import { gql } from '@apollo/client';
import { IndustryModel } from 'domain/models';

export const LIST_INDUSTRY_MODELS_SERVICE = gql`
  query liveIndustryModels($query: String) {
    liveIndustryModels(query: $query) {
      id
      description
      industry
      latestQuarter
    }
  }
`;

export type ListIndustryModelsServiceResponse = {
  liveIndustryModels: IndustryModel[];
};
