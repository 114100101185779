import { GENERAL_REDUCER_ACTIONS, GeneralActionCreator, GeneralReducerFunction } from '../types';

export const toggleDialog: GeneralActionCreator<GENERAL_REDUCER_ACTIONS.TOGGLE_DIALOGS> = (
  value
) => ({
  type: GENERAL_REDUCER_ACTIONS.TOGGLE_DIALOGS,
  value
});

export const toggleDialogsReducer: GeneralReducerFunction<GENERAL_REDUCER_ACTIONS.TOGGLE_DIALOGS> = (
  state,
  payload
) => ({
  ...state,
  activeDialogIDs:
    payload?.action === 'open'
      ? [...state.activeDialogIDs, payload.id]
      : [...state.activeDialogIDs.filter((id) => id !== payload?.id)]
});
