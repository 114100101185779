import { gql } from '@apollo/client';

export const UPDATE_NOTIFICATIONS_QUERY = gql`
  query earningUpdateNotificationQuery {
    earningUpdateNotifications(logsCount: 5) {
      notificationCount
      updatedAt
      seen
      company {
        id
        name
        ticker
      }
    }
  }
`;
