import { gql } from '@apollo/client';

export const LOGOUT_SERVICE = gql`
  mutation Logout {
    logout {
      success
    }
  }
`;

export namespace LogoutService {
  export type Params = void;
  export type Response = void;
}
