import { styled, TextField } from '@mui/material';

export const Container = styled('div')<{ error?: boolean }>(({ error }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  background: 'inherit',

  ...(error && {
    '& label': {
      color: '#D32F2F'
    }
  })
}));

export const Label = styled('label')(() => ({
  position: 'absolute',
  top: '-0.5rem',
  left: '0.75rem',
  padding: '0 0.25rem',
  fontSize: '0.75rem',
  color: '#00000099',
  zIndex: 1,
  background: '#FFFFFF'
}));

export const StyledInput = styled(TextField)(() => ({
  width: '100%'
}));
