import { UserData } from 'domain/models/user-models';
import { Cookies } from 'infra/storage';
import { ActionCreator, CaseReducer, ExtractPayload, ReducerFunction } from 'store/types';

export enum USER_REDUCER_ACTIONS {
  USER_STATE_CLEARED = '@user/stateCleared',
  SET_USER_DATA = '@user/setUserData'
}

export const USER_REDUCER_INITIAL_STATE: UserState = {
  email: Cookies.getSessionCookie(),
  isEnterpriseTier: false,
  isFreeTier: false,
  isStudentTier: false,
  isTrialTier: false,
  maxSubscriptions: 0,
  subscribedCount: 0
};

export type UserState = Partial<UserData> & {
  isEnterpriseTier: boolean;
  isFreeTier: boolean;
  isStudentTier: boolean;
  isTrialTier: boolean;
};

export type UserActionTypes = {
  type: typeof USER_REDUCER_ACTIONS[keyof typeof USER_REDUCER_ACTIONS];
  error?: string;
  value?: Partial<UserState>;
};

type UserActionType =
  | { type: typeof USER_REDUCER_ACTIONS.USER_STATE_CLEARED; value?: undefined }
  | { type: typeof USER_REDUCER_ACTIONS.SET_USER_DATA; value: Partial<UserData> };

export type UserCaseReducer = CaseReducer<UserState, UserActionType>;

export type UserActionCreator<T extends USER_REDUCER_ACTIONS> = ActionCreator<UserActionType, T>;
type UserActionPayload<T extends USER_REDUCER_ACTIONS> = ExtractPayload<UserActionType, T>;

export type UserReducerFunction<T extends USER_REDUCER_ACTIONS> = ReducerFunction<
  UserState,
  UserActionPayload<T>
>;
