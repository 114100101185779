import { styled } from '@mui/material';

export const Container = styled('div')<{ menuExpanded: boolean }>(({ theme, menuExpanded }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '99vh',
  height: 'fit-content',
  width: '100%',
  maxWidth: 'min(calc(100% - 80px), 1302px)',
  margin: '0 auto',
  padding: '0 16px 16px',
  overflow: 'auto',

  [theme.breakpoints.up('xl')]: {
    padding: '0 62px 24px'
  },

  [theme.breakpoints.up('sm')]: {
    maxWidth: `min(${menuExpanded ? 'calc(100% - 290px)' : 'calc(100% - 80px)'}, 1302px)`,
    overflow: 'unset'
  }
}));
