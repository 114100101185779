import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  padding: '36px 16px',

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },

  '& .login-button': {
    marginBottom: '6px'
  },

  '& a button': {
    width: '100%'
  }
});

export const Header = styled('span')({
  fontSize: '24px',
  color: '#000000DE',
  marginBottom: '6px'
});

export const Separator = styled('div')({
  width: '100%',
  borderTop: '1px solid #E8E8E8'
});

export const Alert = styled('div')({
  display: 'flex',
  gap: '0.75rem',
  alignItems: 'center',
  padding: '0.875rem 1rem',
  background: '#FDEDED',
  borderRadius: '4px',
  color: '#5F2120',
  fontWeight: 500,

  '& svg': {
    fontSize: '22px'
  }
});
