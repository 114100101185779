import React, { useState } from 'react';
import { Container, Label, StyledInput } from './TextInput.styles';
import { IconButton, TextFieldProps } from '@mui/material';
import { EyeClosedIcon, EyeOpenIcon } from '../Icons';

type Props = TextFieldProps & {
  label?: string;
  success?: boolean;
  info?: boolean;
  warning?: boolean;
};

export const TextInput = ({
  className,
  id,
  label,
  name,
  type,
  InputProps,
  value,
  error,
  ...rest
}: Props) => {
  const [inputType, setInputType] = useState(type);

  const togglePasswordVisibility = () => {
    if (type === 'password' && !!value) {
      setInputType((prev) => (prev === 'password' ? 'text' : 'password'));
    }
  };

  const eyeIcon = inputType === 'password' ? <EyeClosedIcon /> : <EyeOpenIcon />;
  const EndAddornment =
    type === 'password' ? (
      <IconButton onClick={togglePasswordVisibility}>{eyeIcon}</IconButton>
    ) : (
      InputProps?.endAdornment
    );

  return (
    <Container className={className} error={error}>
      {label && <Label htmlFor={id ?? name}>{label}</Label>}
      <StyledInput
        {...rest}
        id={id ?? name}
        type={inputType}
        name={name}
        value={value}
        error={error}
        InputProps={{
          ...InputProps,
          endAdornment: EndAddornment
        }}
      />
    </Container>
  );
};
