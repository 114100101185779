import React from 'react';
import Props from './types';
import useStyles from './style';
import useLocal from 'localization';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { PopupButton } from '@typeform/embed-react';
import Typography from '@material-ui/core/Typography';
import { useSelectors } from 'presentation/hooks';

const FreeUser: React.FC<Props> = ({ availableModelsNum, usedModelsNum }: Props) => {
  const classes = useStyles();

  const title = useLocal('free_user_title');
  const sub_title_1 = useLocal('free_user_sub_title_1');
  const sub_title_2 = useLocal('free_user_sub_title_2');
  const models_heading = useLocal('free_user_models_heading');
  const models_used = useLocal('free_user_models_used');
  const subscription = useLocal('free_user_subscription');
  const learn = useLocal('free_user_learn');
  const register_access = useLocal('free_user_register_access');
  const inquireHere = useLocal('free_user_inquire');
  const { firstName = '', lastName = '', email = '', phoneNumber = '' } = useSelectors.User();
  const typeformId = 'g4lciCMw';

  const userDetails = {
    name: `${firstName} ${lastName}`,
    email: email,
    phone: phoneNumber?.toString() ?? ''
  };

  return (
    <>
      <Box className={classes.container}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          alignItems={{ sm: 'start', md: 'center' }}
          justifyContent={{ sm: 'start', md: 'space-between' }}
          gridGap={{ xs: '1em', sm: '1em', md: 0 }}
        >
          <Grid item xs={12} sm={12} md={3}>
            <h2 className={classes.h2}>{title}</h2>
            <Typography variant="body2">
              {sub_title_1} <strong>{title}</strong> {sub_title_2}
            </Typography>
          </Grid>
          <Grid className={classes.rightBox} item xs={12} sm={12} md={9}>
            <Grid className={classes.columnBox} item xs={12} sm={12} md={5}>
              <Typography className={classes.availableModels}>{availableModelsNum}</Typography>
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {models_heading}
                </Typography>
                <Typography variant="body2" color="error">
                  {models_used} {usedModelsNum}{' '}
                  {usedModelsNum && usedModelsNum == 1 ? `${subscription}` : `${subscription}s`}
                </Typography>
              </Box>
            </Grid>
            <Grid
              className={`${classes.columnBox} ${classes.columnBox2}`}
              item
              xs={12}
              sm={12}
              md={6}
            >
              <Box>
                <Typography variant="body2">{learn}</Typography>
                <Typography variant="body2">{register_access}</Typography>
              </Box>
              <PopupButton
                id={typeformId}
                className={classes.btn}
                size={50}
                hideFooter
                hideHeaders
                hidden={userDetails}
                autoClose={3000}
              >
                {inquireHere}
              </PopupButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default FreeUser;
