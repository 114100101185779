import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Table, TableCell, TableRow } from '@mui/material';
import {
  LIST_MODELS_SERVICE,
  ListModelsServiceResponse,
  MODEL_SUBSCRIBE_SERVICE,
  MODEL_UNSUBSCRIBE_SERVICE,
  ModelSubscribeServiceResponse,
  ModelSubscribeServiceVariables,
  ModelUnsubscribeServiceResponse,
  ModelUnsubscribeServiceVariables,
  USER_DETAILS_SERVICE
} from 'application/services';
import { ModelStatusPill } from 'components/ModelStatusPill/ModelStatusPill';
import { DIALOG_IDS, MODEL_STATUS, MODEL_SUBSCRIPTION_ERRORS } from 'domain/models';
import { routes } from 'main/router';
import Mixpanel from 'mixPanel';
import { selectCompany } from 'pages/Models/duck/actions';
import {
  DownloadIcon,
  EyeIcon,
  HamburgerIcon,
  SubscribedIcon,
  Tooltip,
  UnsubscribedIcon
} from 'presentation/components';
import { useToastContext } from 'presentation/contexts';
import { useSelectors } from 'presentation/hooks';
import { getFormattedDate } from 'presentation/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import {
  ActionButton,
  CompanyBadge,
  Header,
  HeaderLabel,
  ItemCount,
  PriorityLabel,
  SplashText,
  StyledHomePagePlaceholder,
  StyledSadFaceIcon,
  StyledTableBody,
  StyledTableHead,
  SubscribeButton,
  TableWrapper
} from './ModelsTable.styles';
import { ModelsTablePagination } from './ModelsTablePagination';
import { ModelStatusSelect } from './ModelStatusSelect';
import { toggleDialog } from 'store';

type TableRowData = ListModelsServiceResponse['marketplaceModels']['models'][0];

const PAGE_SIZE = 15;

const isPriority = (priority: string) => {
  const priorityList = ['0', '1'];
  return priorityList.includes(priority);
};

type Props = {
  subscribedOnly?: boolean;
  keyword?: string;
  isSearchPage?: boolean;
};

export const ModelsTable = ({ keyword, subscribedOnly = false, isSearchPage }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToastContext();
  const [queryParams, setQueryParams] = useSearchParams();

  const { isAuthenticated } = useSelectors.Auth();

  const queryPage = Number(queryParams.get('page'));
  const queryStatus = decodeURIComponent(queryParams.get('status') ?? '') as MODEL_STATUS;

  const [page, setPage] = useState(Number.isNaN(queryPage) ? 1 : Math.max(queryPage, 1));
  const [rowLoading, setRowLoading] = useState<number[]>([]);
  const [status, setStatus] = useState<MODEL_STATUS>(
    (!isSearchPage && Object.values(MODEL_STATUS).includes(queryStatus)
      ? queryStatus
      : '') as MODEL_STATUS
  );

  const variables = useMemo(
    () => ({
      page,
      pageSize: PAGE_SIZE,
      isSubscribed: subscribedOnly,
      keyword,
      status
    }),
    [keyword, page, status, subscribedOnly]
  );

  const { loading, error, data, refetch } = useQuery<ListModelsServiceResponse>(
    LIST_MODELS_SERVICE,
    {
      variables,
      fetchPolicy: 'no-cache',
      pollInterval: 1 * 60 * 1000
    }
  );

  const [subscribeModel] = useMutation<
    ModelSubscribeServiceResponse,
    ModelSubscribeServiceVariables
  >(MODEL_SUBSCRIBE_SERVICE, {
    refetchQueries: [USER_DETAILS_SERVICE],
    onError: (error) => {
      toast.error(error.message || 'Failed to subscribe to model');
    }
  });
  const [unsubscribeModel] = useMutation<
    ModelUnsubscribeServiceResponse,
    ModelUnsubscribeServiceVariables
  >(MODEL_UNSUBSCRIBE_SERVICE, {
    refetchQueries: [USER_DETAILS_SERVICE]
  });

  const pageTitle = isSearchPage ? 'Search Results' : 'Data sheets you follow';

  const res = data?.marketplaceModels ?? {
    page: 1,
    pageCount: 1,
    pageSize: PAGE_SIZE,
    totalCount: 0,
    models: [] as TableRowData[]
  };

  const hasData = res.models.length > 0;

  const showTable = hasData || loading;

  const itemCount = useMemo(() => {
    if (loading || error || !res.totalCount) return '0';

    const startCount = res.page * res.pageSize - res.pageSize + 1;
    const endCount = res.page * res.pageSize;
    return `${startCount}-${endCount > res.totalCount ? res.totalCount : endCount}`;
  }, [error, loading, res.page, res.pageSize, res.totalCount]);

  const isModelUpdatingClass = (id: number) => (rowLoading.includes(id) ? 'loading' : '');

  const handlePageNavigation = useCallback(
    (page: number, trigger = 'USER_INTERACTION') => {
      setPage(page);
      queryParams.set('page', page.toString());
      setQueryParams(queryParams);

      Mixpanel.track('marketplaceV2:models:paging', {
        targetPage: page,
        currentPage: res.page,
        pageCount: res.pageCount,
        totalCount: res.totalCount,
        trigger
      });
    },
    [queryParams, res.page, res.pageCount, res.totalCount, setQueryParams]
  );

  const handleModelSubscription = useCallback(
    (modelData: TableRowData, undone?: boolean) => () => {
      if (!isAuthenticated) {
        toast.error('Please login to follow a Data Sheet');
        dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
        return;
      }

      type Response = ModelSubscribeServiceResponse & ModelUnsubscribeServiceResponse;

      const mutation = modelData.isSubscribed ? unsubscribeModel : subscribeModel;

      const eventId = modelData.isSubscribed
        ? 'marketplaceV2:models:unsubscribe_model'
        : 'marketplaceV2:models:activate_model';

      const handleUndo = undone
        ? undefined
        : () => {
            void handleModelSubscription(
              { ...modelData, isSubscribed: !modelData.isSubscribed },
              true
            )();
          };

      setRowLoading((prev) => [...prev, modelData.modelId]);

      return mutation({ variables: { companyId: modelData.companyId } })
        .then((res) => {
          const response = res.data as Partial<Response>;

          const key = Object.keys(response)[0] as 'activateModel' | 'unsubscribeModel';
          const data = response[key];

          if (!data?.success) {
            const error = data?.errors[0];

            const errorMessage = error ? MODEL_SUBSCRIPTION_ERRORS[error] : '';

            toast.error(errorMessage);

            return { success: false, error };
          }

          const toastMessage = undone
            ? 'Action Undone'
            : `Data sheet ${modelData.isSubscribed ? 'unfollowed' : 'followed'}`;
          toast(toastMessage, handleUndo);
        })
        .then((error) => {
          Mixpanel.track(eventId, {
            error,
            companyId: modelData.companyId,
            modelId: modelData.modelId,
            ticker: modelData.companyTicker
          });
        })
        .finally(() => {
          setRowLoading((prev) => prev.filter((id) => id !== modelData.modelId));
          let page = res.page;

          if (modelData.isSubscribed && res.models.length === 1 && !isSearchPage && page > 1) {
            page -= 1;
          }
          handlePageNavigation(page, 'MODEL_SUBSCRIPTION');
          void refetch(variables);
        });
    },
    [
      dispatch,
      handlePageNavigation,
      isAuthenticated,
      isSearchPage,
      refetch,
      res.models.length,
      res.page,
      subscribeModel,
      toast,
      unsubscribeModel,
      variables
    ]
  );

  const handleActionClick = (
    action: 'preview' | 'updates' | 'download',
    modelData: TableRowData
  ) => () => {
    const eventId = `marketplaceV2:models:${action}`;
    Mixpanel.track(eventId, {
      companyId: modelData.companyId,
      modelId: modelData.modelId,
      ticker: modelData.companyTicker
    });

    const download = () => {
      dispatch(
        selectCompany({
          favicon: '',
          id: modelData.companyId.toString(),
          name: modelData.companyName,
          ticker: modelData.companyTicker,
          priority: modelData.priority
        })
      );
      const queryParam = keyword ? `&q=${encodeURIComponent(keyword)}` : '';
      navigate(
        `${routes.DOWNLOAD_MODEL}${queryParam}&modelId=${modelData.modelId}&ticker=${modelData.companyTicker}`
      );
    };

    if (action === 'download') {
      if (!modelData.isSubscribed) {
        handleModelSubscription(modelData)()?.then(download);
      } else {
        download();
      }
    }
  };

  const handleStatusFilter = (value: MODEL_STATUS) => {
    setStatus(value);
    if (value) {
      queryParams.set('status', encodeURIComponent(value));
    } else {
      queryParams.delete('status');
    }
    setQueryParams(queryParams);
    handlePageNavigation(1, 'STATUS_FILTER_CHANGE');
    Mixpanel.track('marketplaceV2:models:status_filter', {
      current: status,
      selected: value
    });
  };

  useEffect(() => {
    if (error) {
      toast.error('Failed to load Data Sheets.');
    }
  }, [error, refetch, toast]);

  useEffect(() => {
    if (!showTable && page > 1) {
      handlePageNavigation(1, 'DATA_UNAVAILABLE');
    }
  }, [handlePageNavigation, page, showTable]);

  return (
    <>
      <Header>
        <HeaderLabel>{pageTitle}</HeaderLabel>
        <div>
          <ItemCount>
            {itemCount} of {res.totalCount}
          </ItemCount>
          {!isSearchPage && <ModelStatusSelect value={status} onChange={handleStatusFilter} />}
        </div>
      </Header>
      {showTable && (
        <>
          <TableWrapper className={isSearchPage ? 'search-page' : ''}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell className="company-name">Company Name</TableCell>
                  <TableCell className="company-ticker">Ticker</TableCell>
                  <TableCell>Starting Period</TableCell>
                  <TableCell>Updated Through</TableCell>
                  <TableCell>Update Timestamp</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </StyledTableHead>
              {!loading && (
                <StyledTableBody>
                  {res.models.map((model) => (
                    <TableRow key={model.modelId} className={isModelUpdatingClass(model.modelId)}>
                      <TableCell className="company-name">
                        <div>
                          <Tooltip title={model.isSubscribed ? 'Unsubscribe' : 'Subscribe'}>
                            <SubscribeButton onClick={handleModelSubscription(model)}>
                              {!model.isSubscribed && <UnsubscribedIcon />}
                              {model.isSubscribed && <SubscribedIcon />}
                            </SubscribeButton>
                          </Tooltip>
                          <CompanyBadge>{model.companyName?.[0]}</CompanyBadge>
                          <Tooltip title={model.companyName}>
                            <span>{model.companyName}</span>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell className="company-ticker">
                        <div>
                          <Tooltip title={model.companyTicker}>
                            <span>{model.companyTicker}</span>
                          </Tooltip>
                          {isPriority(model.priority) && <PriorityLabel>Priority</PriorityLabel>}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>{model.startingQuarter}</div>
                      </TableCell>
                      <TableCell>
                        <div>{model.updatedThrough}</div>
                      </TableCell>
                      <TableCell>
                        <div>{getFormattedDate(model.updatedAt)}</div>
                      </TableCell>
                      <TableCell>
                        <div>{model.status && <ModelStatusPill status={model.status} />}</div>
                      </TableCell>
                      <TableCell className="actions">
                        <div>
                          <Tooltip title="Preview">
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={`${process.env.REACT_APP_BACKEND_URL}/preview/${model.modelId}`}
                              data-testid={`model-preview-link-${model.modelId}`}
                              onClick={handleActionClick('preview', model)}
                            >
                              <ActionButton>
                                <EyeIcon />
                              </ActionButton>
                            </a>
                          </Tooltip>
                          <Tooltip title="Updates">
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={generatePath(routes.LIVE_PREVIEW, {
                                companyId: model.companyId
                              }).concat('?v1=true')}
                              onClick={handleActionClick('updates', model)}
                            >
                              <ActionButton>
                                <HamburgerIcon />
                              </ActionButton>
                            </a>
                          </Tooltip>
                          <Tooltip title="Download">
                            <ActionButton onClick={handleActionClick('download', model)}>
                              <DownloadIcon />
                              Download
                            </ActionButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </StyledTableBody>
              )}
            </Table>
          </TableWrapper>
          {loading && <CircularProgress size={24} style={{ margin: '24px auto' }} />}
          <ModelsTablePagination
            itemCount={itemCount}
            page={res.page}
            pageCount={res.pageCount}
            totalCount={res.totalCount}
            onPageChange={handlePageNavigation}
          />
        </>
      )}
      {!showTable && (
        <>
          {!isSearchPage && (
            <>
              {!status && (
                <>
                  <StyledHomePagePlaceholder />
                  <SplashText>
                    You haven&apos;t followed any Data Sheets yet. Once you do, you&apos;ll be able
                    to view all your followed Data Sheets here and see their earnings update status.
                    We&apos;ll notify you when an earnings update starts and completes. Discover and
                    follow Data Sheets by searching!
                  </SplashText>
                </>
              )}
              {!!status && (
                <SplashText hasFilters>No results found for this update status</SplashText>
              )}
            </>
          )}
          {isSearchPage && (
            <>
              <StyledSadFaceIcon />
              <SplashText noResults>
                We didn&apos;t find any results for ‘{keyword}.’
                <br />
                Please try different words.
              </SplashText>
            </>
          )}
        </>
      )}
    </>
  );
};
