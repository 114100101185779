import React from 'react';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { Outlet } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { generalState } from 'store/slices';
import { Container } from './DefaultLayout.styles';
import { ProviderComposition } from 'main/providers/ProviderComposition';
import { Menu } from 'presentation/components';

type Props = {
  children?: React.ReactNode;
};

export const DefaultLayout = ({ children }: Props) => {
  const { menuExpanded } = useAppSelector(generalState);

  return (
    <ProviderComposition>
      <Menu />
      <Container menuExpanded={menuExpanded}>
        <SearchInput />
        {children}
        <Outlet />
      </Container>
    </ProviderComposition>
  );
};
