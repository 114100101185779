import { authenticatedReducer, authStateClearedReducer, loggedOutReducer } from './actions';

import { AUTH_REDUCER_ACTIONS, AUTH_REDUCER_INITIAL_STATE, AuthCaseReducer } from './types';

export const authReducer: AuthCaseReducer = (
  state = AUTH_REDUCER_INITIAL_STATE,
  { type, value }
) => {
  switch (type) {
    case AUTH_REDUCER_ACTIONS.AUTHENTICATED:
      return authenticatedReducer(state, value);
    case AUTH_REDUCER_ACTIONS.AUTH_STATE_CLEARED:
      return authStateClearedReducer(state, value);
    case AUTH_REDUCER_ACTIONS.LOGOUT:
      return loggedOutReducer(state, value);
    default:
      return state;
  }
};
