import React from 'react';
import { routes } from '../routes';
import { Navigate, RouteObject } from 'react-router';
import InquireHerePopup from 'pages/InquireHerePopup/InquireHerePopup';
import { SearchPage, HomePage } from 'presentation/pages';

export const openRoutes: RouteObject[] = [
  {
    path: routes.INQUIRE_HERE_POPUP,
    element: <InquireHerePopup />
  },
  {
    path: routes.SEARCH,
    element: <SearchPage />
  },
  {
    path: routes.HOME,
    element: <HomePage />
  },
  {
    path: '*',
    element: <Navigate to={routes.HOME} />
  }
];
