import { DIALOG_IDS } from 'domain/models';
import { ActionCreator, CaseReducer, ExtractPayload, ReducerFunction } from 'store/types';

export enum GENERAL_REDUCER_ACTIONS {
  GENERAL_STATE_CLEARED = '@general/stateCleared',
  SET_FEATURE_FLAG = '@general/setFeatureFlag',
  SET_MENU_STATE = '@general/setMenuState',
  SET_V2_BANNER_CLOSED = '@general/setV2BannerClosed',
  TOGGLE_DIALOGS = '@general/toggleDialogs'
}

export const MENU_STATE_KEY = 'marketplace-menu-expanded';

const menuInitialStateExpanded = () =>
  localStorage.getItem(MENU_STATE_KEY) ? localStorage.getItem(MENU_STATE_KEY) === 'true' : true;

export const GENERAL_REDUCER_INITIAL_STATE: GeneralState = (() => {
  const params = new URLSearchParams(window.location.search);

  return {
    activeDialogIDs: [],
    featureFlags: {
      isMarketplaceV2Enabled: !params.get('v1'),
      industryModelsEnabled: true
    },
    menuExpanded: menuInitialStateExpanded(),
    v2BannerClosed: !params.get('v1')
  };
})();

export type GeneralState = {
  activeDialogIDs: DIALOG_IDS[];
  featureFlags: {
    [key: string]: boolean | undefined;
    isMarketplaceV2Enabled?: boolean;
    industryModelsEnabled?: boolean;
  };
  menuExpanded: boolean;
  v2BannerClosed: boolean;
};

type GeneralActionType =
  | { type: typeof GENERAL_REDUCER_ACTIONS.GENERAL_STATE_CLEARED; value?: undefined }
  | { type: typeof GENERAL_REDUCER_ACTIONS.SET_FEATURE_FLAG; value: GeneralState['featureFlags'] }
  | { type: typeof GENERAL_REDUCER_ACTIONS.SET_MENU_STATE; value: GeneralState['menuExpanded'] }
  | {
      type: typeof GENERAL_REDUCER_ACTIONS.SET_V2_BANNER_CLOSED;
      value?: GeneralState['v2BannerClosed'];
    }
  | {
      type: typeof GENERAL_REDUCER_ACTIONS.TOGGLE_DIALOGS;
      value: {
        action: 'open' | 'close';
        id: DIALOG_IDS;
      };
    };

export type GeneralCaseReducer = CaseReducer<GeneralState, GeneralActionType>;

export type GeneralActionCreator<T extends GENERAL_REDUCER_ACTIONS> = ActionCreator<
  GeneralActionType,
  T
>;
type GeneralActionPayload<T extends GENERAL_REDUCER_ACTIONS> = ExtractPayload<GeneralActionType, T>;

export type GeneralReducerFunction<T extends GENERAL_REDUCER_ACTIONS> = ReducerFunction<
  GeneralState,
  GeneralActionPayload<T>
>;
