import { gql } from '@apollo/client';

export const UPDATE_PASSWORD_SERVICE = gql`
  mutation resetUserPasswordWithToken($uid: String!, $token: String!, $password: String!) {
    resetUserPasswordConfirm(uid: $uid, token: $token, password: $password) {
      success
      errors
    }
  }
`;

export namespace UpdatePasswordService {
  export type Params = {
    password: string;
    token: string;
    uid: string;
  };
  export type Response = {
    resetUserPasswordConfirm: {
      success: boolean;
      errors?: string[];
    };
  };
}
