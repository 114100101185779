import {
  GENERAL_REDUCER_ACTIONS,
  GENERAL_REDUCER_INITIAL_STATE,
  GeneralActionCreator,
  GeneralReducerFunction
} from '../types';

export const generalStateCleared: GeneralActionCreator<GENERAL_REDUCER_ACTIONS.GENERAL_STATE_CLEARED> = () => ({
  type: GENERAL_REDUCER_ACTIONS.GENERAL_STATE_CLEARED
});

export const generalStateClearedReducer: GeneralReducerFunction<GENERAL_REDUCER_ACTIONS.GENERAL_STATE_CLEARED> = (
  state
) => ({
  ...state,
  ...GENERAL_REDUCER_INITIAL_STATE
});
