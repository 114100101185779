import {
  USER_REDUCER_ACTIONS,
  USER_REDUCER_INITIAL_STATE,
  UserActionCreator,
  UserReducerFunction
} from '../types';

export const userStateCleared: UserActionCreator<USER_REDUCER_ACTIONS.USER_STATE_CLEARED> = () => ({
  type: USER_REDUCER_ACTIONS.USER_STATE_CLEARED
});

export const userStateClearedReducer: UserReducerFunction<USER_REDUCER_ACTIONS.USER_STATE_CLEARED> = () =>
  USER_REDUCER_INITIAL_STATE;
