import { gql } from '@apollo/client';

export const SIGN_UP_SERVICE = gql`
  mutation createNewUser($email: String!, $password: String!, $hashcode: String!) {
    createNewUser(email: $email, password: $password, hashcode: $hashcode) {
      success
      errors
    }
  }
`;

export namespace SignUpService {
  export type Params = {
    email: string;
    password: string;
    hashcode: string;
  };
  export type Response = {
    createNewUser: {
      success: boolean;
      errors?: string[];
    };
  };
}
