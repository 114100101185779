import { UserTier } from 'domain/models';
import { createSelector } from 'reselect';
import { RootState } from 'store/types';

const userSliceSelector = (state: RootState) => state.user;

export const userState = createSelector(userSliceSelector, (state) => ({
  ...state,
  isEnterpriseTier: state.tier === UserTier.ENTERPRISE,
  isFreeTier: state.tier === UserTier.FREE,
  isStudentTier: state.tier === UserTier.STUDENT,
  isTrialTier: state.tier === UserTier.TRIAL
}));
