import React from 'react';
import { routes } from '../routes';
import { RouteObject } from 'react-router';
import ThankyouVerifyEmail from 'pages/ThankyouVerifyEmail/ThankyouVerifyEmail';
import { ResetPassword, Signup } from 'presentation/pages';

export const authRoutes: RouteObject[] = [
  {
    path: routes.SIGN_UP,
    element: <Signup />
  },
  {
    path: routes.VERIFY_EMAIL,
    element: <ThankyouVerifyEmail />
  },
  {
    path: routes.RESET_PASSWORD,
    element: <ResetPassword />
  }
];
