import { gql } from '@apollo/client';

// TODO: We should no longer receive the token and refreshToken from the login service
//       It would be better to receive success or error messages

export const LOGIN_SERVICE = gql`
  mutation tokenAuthMutation($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      refreshToken
    }
  }
`;

export namespace LoginService {
  export type Params = {
    email: string;
    password: string;
  };
  export type Response = void;
}
