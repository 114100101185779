import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';

import { DialogProps } from '@mui/material';

import { Header, Separator, StyledDialog } from './Dialog.styles';
import { useDispatch } from 'react-redux';
import { toggleDialog } from 'store/slices';
import { DIALOG_IDS } from 'domain/models';
import { useSelectors } from 'presentation/hooks';
import { CloseIcon } from '../Icons';

type Props = Partial<DialogProps> & {
  children: React.ReactNode | (({ onClose }: { onClose: () => void }) => React.ReactNode);
  onClose?: () => void;
  id: DIALOG_IDS;
  header?: React.ReactNode | (({ onClose }: { onClose: () => void }) => React.ReactNode);
};

export const Dialog = memo(({ header, children, id, onClose: handleClose, ...props }: Props) => {
  const dispatch = useDispatch();
  const { activeDialogIDs } = useSelectors.General();

  const initialLoad = useRef(true);
  const dialogOpen = useRef(false);

  const open = useMemo(() => activeDialogIDs.some((activeId) => activeId === id), [
    id,
    activeDialogIDs
  ]);

  const onClose = useCallback(() => {
    dispatch(toggleDialog({ action: 'close', id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (open) {
      initialLoad.current = false;
      dialogOpen.current = true;
    }
    if (!open && !initialLoad.current && dialogOpen.current) {
      dialogOpen.current = false;
      handleClose?.();
    }
  }, [handleClose, open]);

  return (
    <StyledDialog {...props} open={open} onClose={onClose}>
      {header && (
        <>
          {typeof header === 'function' ? (
            header({ onClose })
          ) : (
            <Header>
              {header}
              <CloseIcon onClick={onClose} />
            </Header>
          )}
          <Separator />
        </>
      )}
      {typeof children === 'function' ? children({ onClose }) : children}
    </StyledDialog>
  );
});

Dialog.displayName = 'Dialog';
