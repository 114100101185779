import { Cookies } from 'infra/storage';
import { ActionCreator, CaseReducer, ExtractPayload, ReducerFunction } from 'store/types';

export enum AUTH_REDUCER_ACTIONS {
  AUTHENTICATED = '@auth/authenticated',
  AUTH_STATE_CLEARED = '@auth/stateCleared',
  LOGOUT = '@auth/logout'
}

export const AUTH_REDUCER_INITIAL_STATE: AuthState = {
  isAuthenticated: !!Cookies.getSessionCookie()
};

export type AuthState = {
  isAuthenticated: boolean;
};

export type AuthActionTypes = {
  type: typeof AUTH_REDUCER_ACTIONS[keyof typeof AUTH_REDUCER_ACTIONS];
  error?: string;
  value?: Partial<AuthState>;
};

type AuthActionType =
  | { type: typeof AUTH_REDUCER_ACTIONS.AUTHENTICATED; value?: undefined }
  | { type: typeof AUTH_REDUCER_ACTIONS.AUTH_STATE_CLEARED; value?: undefined }
  | { type: typeof AUTH_REDUCER_ACTIONS.LOGOUT; value?: undefined };

export type AuthCaseReducer = CaseReducer<AuthState, AuthActionType>;

export type AuthActionCreator<T extends AUTH_REDUCER_ACTIONS> = ActionCreator<AuthActionType, T>;
type AuthActionPayload<T extends AUTH_REDUCER_ACTIONS> = ExtractPayload<AuthActionType, T>;

export type AuthReducerFunction<T extends AUTH_REDUCER_ACTIONS> = ReducerFunction<
  AuthState,
  AuthActionPayload<T>
>;
