import { cancel, delay, fork, put, takeLatest } from 'redux-saga/effects';
import { selectState } from 'store/helpers';
import { getLocalValue } from 'localization';
import actionTypes, {
  activateAndDownloadModelAction,
  getModelAction,
  downloadModelAction,
  getModelFromIndustryAction,
  modelObject,
  clientModels,
  unSubscribeAction,
  updatedFundamentalGroup,
  getUpdatedFundamentalGroupsAction,
  getUpdatedFundamentalsAction,
  fundamental,
  updateNotification,
  markAllNotificationsSeenAction,
  markCompanyNotificationSeenAction
} from './types';
import actions from './actions';
import modelsSelectors from './selector';
import Mixpanel from '../../../mixPanel';
import { routerV1, routes } from 'main/router';
import { UPDATE_NOTIFICATIONS_QUERY } from '../constants';
import { Task } from 'redux-saga';
import { gqlClient } from 'infra/http';
import { gql } from '@apollo/client';
import { USER_DETAILS_SERVICE } from 'application/services';

export function* getSectorSaga() {
  yield put(actions.getSectorsPending());
  try {
    const {
      data: { sectors }
    } = yield gqlClient.query({
      query: gql`
        query getSectors {
          sectors {
            totalNumberOfModels
            sectorList {
              name
              modelCount
              id
              industriesSet {
                id
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-first'
    });
    yield put(actions.setSectors(sectors));
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getSectorsError(errorMsg));
  }
}

export function* getModelSaga(action: ReturnType<getModelAction>) {
  const {
    params: { isUpdate, ...rest }
  } = action;

  Mixpanel.track('marketplace:ModelSearch', rest);
  if (isUpdate) {
    yield put(actions.updateModelsPending());
  } else {
    yield put(actions.getModelsPending());
  }

  try {
    const {
      data: {
        paginatedClientModelsV2: { modelsByIndustry, totalNumberOfIndustries }
      }
    } = yield gqlClient.query({
      query: gql`
        query initializeStatesQuery(
          $sectorId: Int
          $numberOfIndustries: Int
          $numberOfModelsPerIndustry: Int
          $keyword: String
          $pageNumber: Int
        ) {
          paginatedClientModelsV2(
            sectorId: $sectorId
            numberOfIndustries: $numberOfIndustries
            numberOfModelsPerIndustry: $numberOfModelsPerIndustry
            keyword: $keyword
            pageNumber: $pageNumber
          ) {
            totalNumberOfIndustries
            modelsByIndustry {
              industry {
                id
                name
              }
              numberOfModelsInIndustry
              clientModels {
                id
                downloadUrl
                latestQuarter
                isSubscribed
                company {
                  id
                  name
                  ticker
                  disabled
                  favicon
                  priority
                }
              }
            }
          }
        }
      `,
      variables: rest,
      fetchPolicy: 'no-cache'
    });
    if (isUpdate) {
      yield put(actions.updateModels(modelsByIndustry));
    } else {
      yield put(actions.setModels(modelsByIndustry, totalNumberOfIndustries));
    }
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getModelsError(errorMsg));
  }
}

function* getModelFromIndustrySaga({
  industryId,
  indexToInsert
}: ReturnType<getModelFromIndustryAction>) {
  yield put(actions.getModelsFromIndustryPending(industryId));
  try {
    const {
      data: { excelModelsForIndustry }
    } = yield gqlClient.query({
      query: gql`
        query excelModelsForIndustryQuery($industryId: Int!) {
          excelModelsForIndustry(industryId: $industryId) {
            id
            downloadUrl
            latestQuarter
            isSubscribed
            company {
              id
              name
              ticker
              favicon
              priority
              disabled
            }
          }
        }
      `,
      variables: { industryId },
      fetchPolicy: 'cache-first'
    });

    let modelList: modelObject[] | null = yield selectState<modelObject[] | null>(
      modelsSelectors.getModelsList
    );
    if (modelList) {
      modelList = modelList.map((value, index) =>
        index !== indexToInsert
          ? { ...value }
          : {
              ...value,
              clientModels: excelModelsForIndustry ?? null
            }
      );
    }

    yield put(actions.setModelsFromIndustry(modelList));
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getModelsError(errorMsg));
  }
}

function* activateAndDownloadModelSaga(action: ReturnType<activateAndDownloadModelAction>) {
  const {
    payload: { companyId, modelId, modelIndex, industryIndex, ticker }
  } = action;

  yield put(actions.setSubscribeModelPending(modelId));
  Mixpanel.track('marketplace:models:activate_model', {
    companyId,
    modelId,
    ticker
  }); //TODO:: delete once checked is not used
  try {
    const {
      data: {
        activateModel: { success }
      }
    } = yield gqlClient.mutate({
      mutation: gql`
        mutation activateModelMutation($companyId: String!) {
          activateModel(companyId: $companyId) {
            success
            userAccessesCompany {
              id
              companyId
              accessMode
            }
          }
        }
      `,
      variables: {
        companyId
      },
      fetchPolicy: 'no-cache'
    });
    if (success) {
      gqlClient.refetchQueries({
        include: [USER_DETAILS_SERVICE]
      });
      const modelList: modelObject[] | null = yield selectState<modelObject[] | null>(
        modelsSelectors.getModelsList
      );
      if (modelList) {
        const newModelList: modelObject[] = modelList.map((data, industryItemIndex) => {
          if (industryItemIndex === industryIndex) {
            const newClientModels: clientModels[] = data.clientModels.map(
              (clinetModel, clinetModelIndex) => {
                if (clinetModelIndex === modelIndex) {
                  return { ...clinetModel, isSubscribed: true };
                } else {
                  return clinetModel;
                }
              }
            );

            return { ...data, clientModels: newClientModels };
          } else {
            return data;
          }
        });
        const totalNumberOfIndustries: number = yield selectState<number>(
          modelsSelectors.getTotalNumberOfIndustries
        );
        yield put(actions.setModels(newModelList, totalNumberOfIndustries));
        yield put(actions.setSubscribeModelPending(null));
      }

      routerV1.navigate(`${routes.DOWNLOAD_MODEL}&modelId=${modelId}&ticker=${ticker}&v1=true`);
    } else {
      yield put(actions.setSubscribeModelPending(null));

      const errorMsg: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'error_msg')
      );

      alert(errorMsg);
    }
  } catch (error) {
    yield put(actions.setSubscribeModelPending(null));
    alert(error);
  }
}

function* fetchTokenAndDownloadModelSaga({
  payload: { modelId, modelType }
}: ReturnType<downloadModelAction>) {
  yield put(actions.downloadModelStatus('PENDING', modelType, modelId));

  try {
    const {
      data: {
        generateModelDownloadToken: { token }
      }
    }: { data: { generateModelDownloadToken: { token: string } } } = yield gqlClient.mutate({
      mutation: gql`
        mutation fetchModelDownloadToken($modelId: String!, $modelType: String!) {
          generateModelDownloadToken(modelId: $modelId, modelType: $modelType) {
            token
          }
        }
      `,
      variables: {
        modelId,
        modelType
      },
      fetchPolicy: 'no-cache'
    });
    if (token && process.env.REACT_APP_BACKEND_URL) {
      yield put(actions.downloadModelStatus('SUCCESS', null, null));
      yield window.location.assign(
        `${process.env.REACT_APP_BACKEND_URL}/excel?x-signed-token=${token}`
      );
    } else {
      Mixpanel.track('marketplace:tokenOrWindowUnavailableForDownload:error', {
        type: 'tokenOrWindowUnavailableForDownload'
      });
    }
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    alert(errorMsg);
    yield put(
      actions.downloadModelError('ERROR', (error as { massage: string })?.massage ?? errorMsg)
    );
  }
}

function* getMyModelSaga() {
  yield put(actions.getModelsPending());

  try {
    const {
      data: { myModels }
    } = yield gqlClient.query({
      query: gql`
        query getMyModels {
          myModels {
            id
            downloadUrl
            latestQuarter
            isSubscribed
            company {
              id
              name
              ticker
              favicon
            }
          }
        }
      `,
      fetchPolicy: 'no-cache'
    });

    yield put(actions.setMyModels(myModels));
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getModelsError(errorMsg));
  }
}

function* unSubscribeModelSaga({ companyId }: ReturnType<unSubscribeAction>) {
  yield put(actions.unSubscribeModelPending());

  try {
    const {
      data: {
        unsubscribeModel: { success, errors }
      }
    } = yield gqlClient.mutate({
      mutation: gql`
        mutation unsubscribeModelMutation($companyId: String!) {
          unsubscribeModel(companyId: $companyId) {
            success
            errors
          }
        }
      `,
      variables: { companyId },
      fetchPolicy: 'no-cache'
    });

    if (success) {
      yield put(actions.unSubscribeModelSuccess());
    } else {
      yield put(actions.unSubscribeModelError(errors[0]));
    }
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.unSubscribeModelError(errorMsg));
  }
}

function* pollUpdateNotificationSaga() {
  try {
    const {
      data: { earningUpdateNotifications }
    } = yield gqlClient.query({
      query: UPDATE_NOTIFICATIONS_QUERY,
      fetchPolicy: 'no-cache'
    });
    yield put(
      actions.getUpdateNotificationSuccess(earningUpdateNotifications as updateNotification[])
    );
  } catch {}
}

function* getUpdatedFundamentalGroupSaga({
  companyId
}: ReturnType<getUpdatedFundamentalGroupsAction>) {
  yield put(actions.getUpdatedFundamentalGroupsPending());
  try {
    const {
      data: { updatedFundamentalGroups }
    } = yield gqlClient.query({
      query: gql`
        query updatedFundamentalGroupsQuery($companyId: String!) {
          updatedFundamentalGroups(companyId: $companyId) {
            fundamentalGroups {
              id
              label
              updatedAt
              earningsUpdatedAt
            }
            document {
              id
              filingType
              documentType
              dropTime
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        companyId
      }
    });
    yield put(
      actions.getUpdatedFundamentalGroupsSuccess(
        updatedFundamentalGroups as updatedFundamentalGroup
      )
    );
  } catch (error) {
    yield put(actions.getUpdatedFundamentalGroupsError());
  }
}

function* getUpdatedFundamentalsSaga({
  companyId,
  groupId
}: ReturnType<getUpdatedFundamentalsAction>) {
  yield put(actions.getUpdatedFundamentalsPending(groupId));
  try {
    const {
      data: { updatedFundamentals }
    } = yield gqlClient.query({
      query: gql`
        query updatedFundamentalsQuery($companyId: String!, $groupId: String!) {
          updatedFundamentals(companyId: $companyId, groupIds: [$groupId]) {
            id
            type
            value
            normalizedValue
            period
            context
            excelTag {
              name
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        companyId,
        groupId
      }
    });
    yield put(actions.getUpdatedFundamentalsSuccess(updatedFundamentals as fundamental[], groupId));
  } catch (error) {
    yield put(actions.getUpdatedFundamentalsError());
  }
}

function* markAllNotificationSeen({ companyIds }: ReturnType<markAllNotificationsSeenAction>) {
  yield put(actions.markAllNotificationsSeenPending());
  try {
    const {
      data: {
        markEarningsUpdateUserNotification: { success }
      }
    } = yield gqlClient.query<{ markEarningsUpdateUserNotification: { success: true } }>({
      query: gql`
        mutation markEarningsUpdateUserNotification($companyIds: [String]!) {
          markEarningsUpdateUserNotification(companyIds: $companyIds, seen: true) {
            success
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        companyIds
      }
    });
    if (success) {
      yield put(actions.markAllNotificationsSeenSuccess());
    } else {
      yield put(actions.markAllNotificationsSeenError());
    }
  } catch (error) {
    yield put(actions.markAllNotificationsSeenError());
  }
}

function* markCompanyNotificationSeen({
  companyId
}: ReturnType<markCompanyNotificationSeenAction>) {
  yield put(actions.markCompanyNotificationSeenPending(companyId));
  try {
    const {
      data: {
        markEarningsUpdateUserNotification: { success }
      }
    } = yield gqlClient.query<{ markEarningsUpdateUserNotification: { success: true } }>({
      query: gql`
        mutation markEarningsUpdateUserNotification($companyIds: [String]!) {
          markEarningsUpdateUserNotification(companyIds: $companyIds, seen: true) {
            success
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        companyIds: [companyId]
      }
    });
    if (success) {
      yield put(actions.markCompanyNotificationSeenSuccess(companyId));
    } else {
      yield put(actions.markCompanyNotificationSeenError());
    }
  } catch (error) {
    yield put(actions.markCompanyNotificationSeenError());
  }
}

export function* getEarningUpdateNotificationConfigSaga() {
  try {
    const {
      data: { getConfigurationSetting }
    } = yield gqlClient.query({
      query: gql`
        query getConfigurationSettingQuery($key: String!) {
          getConfigurationSetting(key: $key)
        }
      `,
      variables: { key: 'EARNING_UPDATE_NOTIFICATIONS' },
      fetchPolicy: 'no-cache'
    });
    yield put(actions.setEarningUpdateNotificationConfig(JSON.parse(getConfigurationSetting)));
  } catch (error) {}
}

const POLLING_DELAY = 60000 * 2;

export function* cancelPolling(pollTask: Task) {
  yield cancel(pollTask);
}
export function* pollingNotificationInLoop() {
  while (true) {
    try {
      yield fork(pollUpdateNotificationSaga);
      // optional delay
      yield delay(POLLING_DELAY);
    } catch (e) {
      console.error(e);
    }
  }
}

export function* pollOrCancelUpdateNotification(): any {
  const pollTask = yield fork(pollingNotificationInLoop);
  yield watchCancelPoll(pollTask);
}

export function* watchCancelPoll(pollTask: any) {
  yield takeLatest(actionTypes.STOP_POLLING, () => cancelPolling(pollTask));
}

export default function* modelPageSaga() {
  yield takeLatest(actionTypes.GET_SECTORS, getSectorSaga);
  yield takeLatest(actionTypes.GET_MODELS, getModelSaga);
  yield takeLatest(actionTypes.GET_MY_MODELS, getMyModelSaga);
  yield takeLatest(actionTypes.GET_MODELS_FROM_INDUSTRY, getModelFromIndustrySaga);
  yield takeLatest(actionTypes.ACTIVATE_AND_DOWNLOAD_MODELS, activateAndDownloadModelSaga);
  yield takeLatest(actionTypes.DOWNLOAD_MODELS, fetchTokenAndDownloadModelSaga);
  yield takeLatest(actionTypes.UNSUBSCRIBE, unSubscribeModelSaga);
  yield takeLatest(actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS, getUpdatedFundamentalGroupSaga);
  yield takeLatest(actionTypes.GET_UPDATED_FUNDAMENTALS, getUpdatedFundamentalsSaga);
  yield takeLatest(
    actionTypes.GET_EARNING_UPDATE_NOTIFICATION_CONFIG,
    getEarningUpdateNotificationConfigSaga
  );
  yield takeLatest(actionTypes.MARK_ALL_NOTIFICATIONS_SEEN, markAllNotificationSeen);
  yield takeLatest(actionTypes.MARK_COMPANY_NOTIFICATION_SEEN, markCompanyNotificationSeen);
  yield takeLatest(actionTypes.GET_UPDATE_NOTIFICATIONS, pollOrCancelUpdateNotification);
}
