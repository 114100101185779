import React, { memo } from 'react';
import { LoginDialog } from './login/LoginDialog';
import { ResetPasswordDialog } from './reset-password/ResetPasswordDialog';
import { UpdatePasswordDialog } from './update-password/UpdatePasswordDialog';
import { SignupDialog } from './signup/SignupDialog';

export const ApplicationDialogs = memo(() => {
  return (
    <>
      <LoginDialog />
      <UpdatePasswordDialog />
      <ResetPasswordDialog />
      <SignupDialog />
    </>
  );
});
