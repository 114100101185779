import { GENERAL_REDUCER_ACTIONS, GeneralActionCreator, GeneralReducerFunction } from '../types';

export const setV2BannerClosed: GeneralActionCreator<GENERAL_REDUCER_ACTIONS.SET_V2_BANNER_CLOSED> = (
  v2BannerClosed = true
) => ({
  type: GENERAL_REDUCER_ACTIONS.SET_V2_BANNER_CLOSED,
  value: v2BannerClosed
});

export const setV2BannerClosedReducer: GeneralReducerFunction<GENERAL_REDUCER_ACTIONS.SET_V2_BANNER_CLOSED> = (
  state,
  payload
) => ({
  ...state,
  v2BannerClosed: !!payload
});
