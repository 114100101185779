import JSCookie from 'js-cookie';

import { CookieAttributes, CookieStorage, SESSION_COOKIE_NAME } from 'application/protocols';

class Cookie implements CookieStorage {
  get(key: string) {
    return JSCookie.get(key);
  }

  set(key: string, value?: string | Record<string, unknown>, options?: CookieAttributes) {
    if (!value) {
      JSCookie.remove(key, options);
      return;
    }

    JSCookie.set(key, value, options);
  }

  getSessionCookie = () => {
    return JSCookie.get(SESSION_COOKIE_NAME);
  };

  removeSessionCookie = () => {
    const domain = process.env.REACT_APP_ENV === 'development' ? 'localhost' : '.daloopa.com';
    JSCookie.remove(SESSION_COOKIE_NAME, { domain });
  };
}

export const Cookies = new Cookie();
