import { useMutation } from '@apollo/client';
import {
  LOGIN_SERVICE,
  LoginService,
  LOGOUT_SERVICE,
  LogoutService,
  RESET_PASSWORD_SERVICE,
  ResetPasswordService,
  SIGN_UP_SERVICE,
  SignUpService,
  UPDATE_PASSWORD_SERVICE,
  UpdatePasswordService,
  USER_DETAILS_SERVICE
} from 'application/services';

export const useAuthServices = () => {
  const useLogin = () =>
    useMutation<LoginService.Response, LoginService.Params>(LOGIN_SERVICE, {
      refetchQueries: [USER_DETAILS_SERVICE]
    });

  const useLogout = () => useMutation<LogoutService.Response, LogoutService.Params>(LOGOUT_SERVICE);

  const useResetPassword = () =>
    useMutation<ResetPasswordService.Response, ResetPasswordService.Params>(RESET_PASSWORD_SERVICE);

  const useSignUp = () =>
    useMutation<SignUpService.Response, SignUpService.Params>(SIGN_UP_SERVICE);

  const useUpdatePassword = () =>
    useMutation<UpdatePasswordService.Response, UpdatePasswordService.Params>(
      UPDATE_PASSWORD_SERVICE
    );

  return {
    login: useLogin,
    logout: useLogout,
    resetPassword: useResetPassword,
    signup: useSignUp,
    updatePassword: useUpdatePassword
  };
};
