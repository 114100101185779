import Cookies from 'js-cookie';

export const onCookieChange = (
  cookieName: string,
  callback: (value: string | undefined) => void,
  interval = 10000
) => {
  let lastValue = Cookies.get(cookieName);
  let timeoutId: NodeJS.Timeout | null = null;

  const cleanup = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  const checkCookie = () => {
    const currentValue = Cookies.get(cookieName);
    if (currentValue !== lastValue) {
      lastValue = currentValue;
      callback(currentValue);
    }
    timeoutId = setTimeout(checkCookie, interval);
  };

  timeoutId = setTimeout(checkCookie, interval);

  return cleanup; // Return a function to clear the timeout
};
