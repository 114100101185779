import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStatsigClient } from '@statsig/react-bindings';
import EarningsUpdateActionNotifications from 'components/EarningsUpdateActionNotifications/EarningsUpdateActionNotifications';
import {
  BellIcon,
  BookIcon,
  DaloopaLogoIcon,
  DaloopaPlusLogoIcon,
  HomeIcon,
  InstanceIcon,
  ListIcon,
  LoginIcon,
  LogoutIcon,
  MailIcon,
  MenuCollapseIcon,
  MenuExpandIcon,
  SettingsIcon,
  Tooltip
} from 'presentation/components';
import { routes } from 'main/router';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setMenuState } from 'store';
import { loggedOut, toggleDialog, userStateCleared } from 'store/slices';
import {
  Container,
  ContentWrapper,
  MenuButton,
  MenuHeader,
  MenuMainNavigation,
  MenuSubNavigation,
  NotificationBadge,
  OldMarketPlaceButton
} from './Menu.styles';
import { modelsSelectors } from 'pages/Models/duck';
import { Mixpanel } from 'infra/mixpanel';
import modelsActions from 'pages/Models/duck/actions';
import notificationsSelector from 'pages/Models/duck/selector';
import { DIALOG_IDS } from 'domain/models';
import { useAuthServices, useSelectors } from 'presentation/hooks';
import { Cookies } from 'infra/storage';

export const Menu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const statsig = useStatsigClient().client;

  const notificationButtonRef = useRef<HTMLButtonElement>(null);

  const { menuExpanded } = useSelectors.General();
  const { isAuthenticated } = useSelectors.Auth();
  const { productType } = useSelectors.User();

  const [logout] = useAuthServices().logout();

  const [expanded, setExpanded] = useState(menuExpanded);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const isEarningNotificationsEnabled = useSelector(modelsSelectors.isEarningNotificationsEnabled);
  const NOTIFICATIONS_POLLING = process.env.REACT_APP_NOTIFICATIONS_POLLING === 'true';

  const isPlus = useMemo(
    () => productType === 'daloopa_plus' || productType === 'daloopa_plus_time_ltd',
    [productType]
  );

  const earningsUpdateNotificationsUnseenCount = useSelector(
    notificationsSelector.earningsUpdateNotificationsUnSeenCount
  );

  const notificationsCount = useMemo(
    () =>
      earningsUpdateNotificationsUnseenCount > 999
        ? '999+'
        : earningsUpdateNotificationsUnseenCount,
    [earningsUpdateNotificationsUnseenCount]
  );

  const isSrcPage = location.pathname.includes('/src/');

  const handleToggleMenu = () => {
    setExpanded((prev) => {
      const state = !prev;

      Mixpanel.track('marketplaceV2:menu:toggle_state', { state });

      dispatch(setMenuState(state));
      return state;
    });
  };

  const handleLoginClick = () => {
    Mixpanel.track('marketplaceV2:menu:login');

    dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
  };
  const handleLogout = () => {
    Mixpanel.track('marketplaceV2:menu:logout');
    logout().finally(() => {
      navigate(routes.HOME);
      Cookies.removeSessionCookie();
      dispatch(userStateCleared());
      dispatch(loggedOut());
    });
  };

  const handleIndustryModels = () => {
    Mixpanel.track('marketplaceV2:menu:industry_models');
    navigate(routes.INDUSTRY);
  };

  const handleSettings = () => {
    Mixpanel.track('marketplaceV2:menu:settings');
    navigate(routes.SETTINGS);
  };

  const handleShowNotifications = () => {
    setNotificationsOpen(true);
    Mixpanel.track('marketplaceV2:menu:notification_bell_icon:click');
  };
  const handleHideNotifications = () => {
    setNotificationsOpen(false);
    Mixpanel.track('marketplaceV2:menu:notifications:close');
  };
  const handleDocumentationClick = () => {
    Mixpanel.track('marketplaceV2:menu:documentation');
  };
  const handleSupportClick = () => {
    Mixpanel.track('marketplaceV2:menu:support');
  };

  const handleOldMarketplaceClick = () => {
    Mixpanel.track('marketplaceV2:menu:old_marketplace');
  };

  useEffect(() => {
    if (isAuthenticated && isEarningNotificationsEnabled && NOTIFICATIONS_POLLING && !isSrcPage) {
      dispatch(modelsActions.getUpdateNotifications());
    } else {
      dispatch(modelsActions.stopPolling());
    }
  }, [isEarningNotificationsEnabled, isAuthenticated, NOTIFICATIONS_POLLING, isSrcPage, dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(modelsActions.getEarningUpdateNotificationConfig());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <Container open={expanded}>
      <ContentWrapper>
        <MenuHeader>
          {expanded && (
            <>
              {!isPlus && <DaloopaLogoIcon className="daloopa-icon" />}
              {isPlus && <DaloopaPlusLogoIcon className="daloopa-icon" />}
            </>
          )}
          <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
            <MenuButton className="menu-toggle" onClick={handleToggleMenu}>
              {expanded ? <MenuCollapseIcon /> : <MenuExpandIcon />}
            </MenuButton>
          </Tooltip>
        </MenuHeader>
        <MenuMainNavigation>
          <Link to={routes.HOME}>
            <Tooltip title="Home">
              <MenuButton onClick={(e) => e.currentTarget.blur()}>
                <HomeIcon />
                {expanded && <div>Home</div>}
              </MenuButton>
            </Tooltip>
          </Link>
          {isAuthenticated && (
            <Tooltip title="Industry Models">
              <MenuButton onClick={handleIndustryModels}>
                <ListIcon />
                {expanded && <div>Industry Models</div>}
              </MenuButton>
            </Tooltip>
          )}
          {process.env.REACT_APP_HELP_PAGE_LINK && (
            <a
              href={process.env.REACT_APP_HELP_PAGE_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={handleDocumentationClick}
            >
              <Tooltip title="Documentation">
                <MenuButton>
                  <BookIcon />
                  {expanded && <div>Documentation</div>}
                </MenuButton>
              </Tooltip>
            </a>
          )}
        </MenuMainNavigation>
        <MenuSubNavigation>
          <a
            href="mailto:support@daloopa.com"
            target="_blank"
            rel="noreferrer"
            onClick={handleSupportClick}
          >
            <Tooltip title="Support">
              <MenuButton>
                <MailIcon />
                {expanded && <div>Support</div>}
              </MenuButton>
            </Tooltip>
          </a>
          {isAuthenticated && (
            <>
              <Tooltip title="Alerts">
                <MenuButton ref={notificationButtonRef} onClick={handleShowNotifications}>
                  <BellIcon />
                  {expanded && <div>Alerts</div>}
                  {!!notificationsCount && (
                    <NotificationBadge className="notifications-badge">
                      {notificationsCount}
                    </NotificationBadge>
                  )}
                </MenuButton>
              </Tooltip>
              <EarningsUpdateActionNotifications
                anchorEl={notificationButtonRef.current}
                open={notificationsOpen}
                onClose={handleHideNotifications}
              />
              {statsig.checkGate('enable-marketplace-v2-settings') && (
                <Tooltip title="Settings">
                  <MenuButton onClick={handleSettings}>
                    <SettingsIcon />
                    {expanded && <div>Settings</div>}
                  </MenuButton>
                </Tooltip>
              )}
              <Tooltip title="Logout">
                <MenuButton onClick={handleLogout}>
                  <LogoutIcon />
                  {expanded && <div>Logout</div>}
                </MenuButton>
              </Tooltip>
            </>
          )}
          {!isAuthenticated && (
            <Tooltip title="Login">
              <MenuButton onClick={handleLoginClick}>
                <LoginIcon />
                {expanded && <div>Login</div>}
              </MenuButton>
            </Tooltip>
          )}
          <Tooltip title="Switch to old Marketplace">
            <OldMarketPlaceButton
              href={`${routes.HOME}?v1=true`}
              target="_blank"
              rel="noreferrer"
              onClick={handleOldMarketplaceClick}
            >
              <InstanceIcon />
              {expanded && <span>Switch to old Marketplace</span>}
            </OldMarketPlaceButton>
          </Tooltip>
        </MenuSubNavigation>
      </ContentWrapper>
    </Container>
  );
};
