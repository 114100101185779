import React from 'react';
import { Alert, Container, Details } from './ResetPasswordDialog.styles';
import { Button, Dialog, ErrorOutlinedIcon, TextInput } from 'presentation/components';
import { DIALOG_IDS } from 'domain/models';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
import Mixpanel from 'mixPanel';
import { CircularProgress } from '@mui/material';
import { toggleDialog } from 'store';
import { useToastContext } from 'presentation/contexts';
import { useAuthServices } from 'presentation/hooks';

const resetPasswordObjectShape = object({
  email: string().email().required()
});

export const ResetPasswordDialog = () => {
  const dispatch = useDispatch();
  const toast = useToastContext();

  const [resetPassword, { loading, error }] = useAuthServices().resetPassword();

  const formikObj = useFormik<{
    email: string;
  }>({
    initialValues: {
      email: ''
    },
    validationSchema: resetPasswordObjectShape,
    onSubmit: ({ email }) => {
      Mixpanel.track('marketplace:forget_password:form:submit', { email });
      void resetPassword({ variables: { email } }).then(({ data }) => {
        if (data?.resetUserPassword.success) {
          toast('We have sent an email to reset your password.');
          dispatch(toggleDialog({ action: 'close', id: DIALOG_IDS.RESET_PASSWORD }));
          formikObj.resetForm();
        } else {
          toast.error('Something went wrong. Please try again later.');
        }
      });
    }
  });

  const hasErrors = (!formikObj.isValid && formikObj.submitCount > 0) || error;

  return (
    <Dialog id={DIALOG_IDS.RESET_PASSWORD} onClose={formikObj.resetForm} header="Reset Password">
      <Details>Enter your email registered to reset password</Details>
      <Container>
        {hasErrors && (
          <Alert>
            <ErrorOutlinedIcon /> Missing or invalid email
          </Alert>
        )}
        <form onSubmit={formikObj.handleSubmit}>
          <TextInput
            name="email"
            placeholder="example@email.com"
            label="Email Address"
            autoComplete="email"
            onChange={formikObj.handleChange}
            value={formikObj.values.email}
            onBlur={formikObj.handleBlur}
            disabled={loading}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            className="reset-button"
            disabled={loading}
          >
            {!loading && 'Reset password'}
            {loading && <CircularProgress size={24} />}
          </Button>
        </form>
      </Container>
    </Dialog>
  );
};
