import { userStateClearedReducer } from './actions/userStateCleared';
import { setUserDataReducer } from './actions/setUserData';
import { USER_REDUCER_ACTIONS, USER_REDUCER_INITIAL_STATE, UserCaseReducer } from './types';

export const userReducer: UserCaseReducer = (
  state = USER_REDUCER_INITIAL_STATE,
  { type, value }
) => {
  switch (type) {
    case USER_REDUCER_ACTIONS.USER_STATE_CLEARED:
      return userStateClearedReducer(state, value);
    case USER_REDUCER_ACTIONS.SET_USER_DATA:
      return setUserDataReducer(state, value);
    default:
      return state;
  }
};
