import React from 'react';
import { DefaultLayout, OldLayout } from 'main/layout';
import { ErrorBoundary, RouterErrorBoundary } from 'presentation/components';
import { RouteObject } from 'react-router';
import { authRoutes } from './auth-routes';
import { errorRoutes } from './error-routes';
import { openRoutes } from './open-routes';
import { protectedRoutes } from './protected-routes';

export const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <DefaultLayout />
      </ErrorBoundary>
    ),
    ErrorBoundary: RouterErrorBoundary,
    children: [...errorRoutes, ...authRoutes, ...protectedRoutes, ...openRoutes]
  }
];

export const appRoutesV1: RouteObject[] = [
  {
    path: '*',
    element: (
      <ErrorBoundary>
        <OldLayout />
      </ErrorBoundary>
    ),
    ErrorBoundary: RouterErrorBoundary
  }
];
