import React from 'react';
import { ModelsTable } from 'presentation/components';
import { useSelectors } from 'presentation/hooks';
import { HomePagePlaceholder, UserBanner } from './components';
import { AuthorizedContainer, Container, SplashText } from './HomePage.styles';

export const HomePage = () => {
  const { isAuthenticated } = useSelectors.Auth();

  if (!isAuthenticated) {
    return (
      <Container>
        <HomePagePlaceholder />
        <SplashText>
          Once you login, you&apos;ll be able to view all your followed Data Sheets here and see
          their earnings update status. We&apos;ll notify you when an earnings update starts and
          completes.
          <br />
          Discover and follow Data Sheets by searching!
        </SplashText>
      </Container>
    );
  }
  return (
    <AuthorizedContainer>
      <UserBanner />
      <ModelsTable subscribedOnly />
    </AuthorizedContainer>
  );
};

export default HomePage;
