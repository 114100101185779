import { USER_REDUCER_ACTIONS, UserActionCreator, UserReducerFunction } from '../types';

export const setUserData: UserActionCreator<USER_REDUCER_ACTIONS.SET_USER_DATA> = (userData) => ({
  type: USER_REDUCER_ACTIONS.SET_USER_DATA,
  value: userData
});

export const setUserDataReducer: UserReducerFunction<USER_REDUCER_ACTIONS.SET_USER_DATA> = (
  state,
  userData = {}
) => ({
  ...state,
  ...userData
});
