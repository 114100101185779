import { createSelector } from 'reselect';

import { RequestNewModelState } from './types';
import { RootState } from 'store/types';

const getRequestNewModelState = (state: RootState): RequestNewModelState =>
  state.RequestNewModelReducer;

const hasError = createSelector(
  [getRequestNewModelState],
  (RequestNewModelState: RequestNewModelState): boolean => RequestNewModelState.isError
);
const getError = createSelector(
  [getRequestNewModelState],
  (RequestNewModelState: RequestNewModelState): string | null => RequestNewModelState.error
);

const isLoading = createSelector(
  [getRequestNewModelState],
  (RequestNewModelState: RequestNewModelState): boolean => RequestNewModelState.isLoading
);

export default {
  hasError,
  getError,
  isLoading
};
