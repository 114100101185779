import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { fork } from 'redux-saga/effects';
import { composeWithDevTools } from 'redux-devtools-extension';
import localizationReducer from 'localization/reducer';
import ModelsReducer from 'pages/Models/duck';
import { ModelsSaga } from 'pages/Models/duck';
import IndustryModelReducer from 'pages/IndustryModels/duck';
import { IndustryModelsSaga } from 'pages/IndustryModels/duck';
import RequestModelReducer from 'pages/RequestModel/duck';
import { RequestModelSaga } from 'pages/RequestModel/duck';
import { RequestNewModelSaga } from 'pages/RequestNewModel/duck';
import RequestNewModelReducer from 'pages/RequestNewModel/duck';
import ApiSettingsReducer from 'pages/ApiSettings/duck';
import { ApiSettingsSaga } from 'pages/ApiSettings/duck';
import { ExcelPluginSaga } from 'pages/ExcelPlugin/duck';
import ExcelPluginReducer from 'pages/ExcelPlugin/duck';
import TagViewerReducer from 'pages/TagViewer/duck';
import { TagViewerSaga } from 'pages/TagViewer/duck';
import ThankyouVerifyEmailReducer from 'pages/ThankyouVerifyEmail/duck';
import { ThankyouVerifyEmailSaga } from 'pages/ThankyouVerifyEmail/duck';
import InquireHerePopupReducer from 'pages/InquireHerePopup/duck';
import { all } from '@redux-saga/core/effects';
import { authReducer, generalReducer, userReducer } from './slices';

const rootReducer = combineReducers({
  auth: authReducer,
  general: generalReducer,
  user: userReducer,
  localizationReducer,
  ModelsReducer,
  IndustryModelReducer,
  RequestModelReducer,
  RequestNewModelReducer,
  ApiSettingsReducer,
  ExcelPluginReducer,
  TagViewerReducer,
  ThankyouVerifyEmailReducer,
  InquireHerePopupReducer
});
function* rootSaga() {
  yield all([
    fork(ModelsSaga),
    fork(IndustryModelsSaga),
    fork(RequestModelSaga),
    fork(RequestNewModelSaga),
    fork(ApiSettingsSaga),
    fork(ExcelPluginSaga),
    fork(TagViewerSaga),
    fork(ThankyouVerifyEmailSaga)
  ]);
}

const sagaMiddleware = createSagaMiddleware();

export const makeStore = () => {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(rootSaga);
  return store;
};

export * from './slices/actions';

export const store = makeStore();
