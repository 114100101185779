import { Badge, Button } from '@material-ui/core';
import { Menu as MetrialMenu } from '@material-ui/core/';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Notifications } from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Classnames from 'classnames';
import ExcelIcon from 'components/ExcelIcon';
import ModelFreeTrierTooltip from 'components/ModelFreeTrierTooltip';
import WhiteTooltip from 'components/WhiteTooltip';
import useLocal from 'localization';
import { SET_SHOW_TRANSLATION_KEYS } from 'localization/reducer';
import { routes } from 'main/router';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import EarningsUpdateActionNotifications from '../../components/EarningsUpdateActionNotifications';
import Mixpanel from '../../mixPanel';
import notificationsSelector from '../../pages/Models/duck/selector';
import useStyles from './style';
import { useAuthServices, useSelectors } from 'presentation/hooks';
import { loggedOut, toggleDialog, userStateCleared } from 'store/slices';
import { DIALOG_IDS } from 'domain/models';
import { Cookies } from 'infra/storage';

const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const classes = useStyles();
  const StyledTab = withStyles((theme: Theme) => ({
    wrapper: {
      flexDirection: 'row',
      background: theme.palette.primary.dark,
      justifyContent: 'space-evenly',
      width: '70%',
      paddingTop: theme.spacing(0.7),
      alignItems: 'flex-start'
    }
  }))(Tab);

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const { isAuthenticated } = useSelectors.Auth();

  const modelText = useLocal('model');
  const apiSettingsText = useLocal('api_settings');
  const kpiKey = useLocal('kpi');
  const supportText = useLocal('support');

  const logoutText = useLocal('logout');
  const loginText = useLocal('login');
  const pluginText = useLocal('plugin');
  const documentationText = useLocal('documentation');
  const { isFreeTier } = useSelectors.User();
  const earningsUpdateNotificationsUnSeenCount = useSelector(
    notificationsSelector.earningsUpdateNotificationsUnSeenCount
  );
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null);

  const [logout] = useAuthServices().logout();

  const onAccountLinkClick = (event: React.MouseEvent) => {
    Mixpanel.track('marketplace:menu:account_menu:click');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(anchorEl ? null : event.currentTarget);
    Mixpanel.track('marketplace:menu:notification_bell_icon:click');
  };

  const onModelsClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:models:click');
  }, []);

  const onSupportClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:support:click');
    window.open('mailto:support@daloopa.com');
  }, []);

  const onAPISettingsClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:api_settings:click', { isLoggedIn: isAuthenticated });
    if (!isAuthenticated) {
      dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
    }
  }, [dispatch, isAuthenticated]);

  const onKPILinkClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:kpi_link:click');
  }, []);

  const onDocumentationLinkClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:documentation_link:click');
  }, []);

  const onLogoutClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:logout:click');
    logout().finally(() => {
      navigate(routes.HOME);
      Cookies.removeSessionCookie();
      dispatch(userStateCleared());
      dispatch(loggedOut());
    });
  }, [dispatch, logout, navigate]);

  const onLoginClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:login:click');

    handleClose();
    dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
  }, [dispatch, handleClose]);

  const onPluginClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:excel_plugin:click');
    navigate(routes.EXCEL_PLUGIN);
  }, [navigate]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed['showTranslationKeys'] === 'true') {
      dispatch({
        type: SET_SHOW_TRANSLATION_KEYS,
        showTranslationKeys: true
      });
    } else {
      dispatch({
        type: SET_SHOW_TRANSLATION_KEYS,
        showTranslationKeys: false
      });
    }
  }, [dispatch, location.search]);

  return (
    <Tabs value={location.pathname !== routes.HOME ? location.pathname : false}>
      <Tab
        label={modelText}
        value={routes.MODELS}
        component={Link}
        to={routes.MODELS}
        className={classes.menuLink}
        data-testid="model-page-link"
        onClick={onModelsClick}
      />
      <Tab label={supportText} className={classes.menuLink} onClick={onSupportClick} />

      {isAuthenticated ? (
        <Tab
          label={apiSettingsText}
          value={routes.API_SETTINGS}
          component={Link}
          to={routes.API_SETTINGS}
          className={classes.menuLink}
          data-testid="api-settings-link"
          onClick={onAPISettingsClick}
        />
      ) : (
        <Button
          variant={'text'}
          className={Classnames(classes.menuLink, classes.apiSettingsButton)}
          data-testid="api-settings-link"
          onClick={onAPISettingsClick}
        >
          {apiSettingsText}
        </Button>
      )}
      <Tab
        label={kpiKey}
        component="a"
        href={process.env.REACT_APP_KPI_LINK}
        className={classes.menuLink}
        data-kpi="kpi-link"
        target="_blank"
        onClick={onKPILinkClick}
      />
      <Tab
        label={documentationText}
        component="a"
        href={process.env.REACT_APP_HELP_PAGE_LINK}
        className={classes.menuLink}
        data-testid="help-page-link"
        target="_blank"
        onClick={onDocumentationLinkClick}
      />
      <Tab className={classes.iconLink} icon={<AccountCircleIcon />} onClick={onAccountLinkClick} />

      {isAuthenticated && (
        <Tab
          className={classes.iconLink}
          icon={
            <Badge badgeContent={earningsUpdateNotificationsUnSeenCount} color={'error'}>
              <Notifications />
            </Badge>
          }
          onClick={handleNotificationClick}
        />
      )}

      <EarningsUpdateActionNotifications
        anchorEl={notificationAnchorEl}
        onClose={() => setNotificationAnchorEl(null)}
      />

      <MetrialMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {isAuthenticated && <MenuItem onClick={onLogoutClick}>{logoutText}</MenuItem>}
        {!isAuthenticated && <MenuItem onClick={onLoginClick}>{loginText}</MenuItem>}
      </MetrialMenu>
      {isFreeTier ? (
        <WhiteTooltip title={<ModelFreeTrierTooltip showNotAllowed />} interactive>
          <span>
            <StyledTab icon={<ExcelIcon />} label={pluginText} disabled onClick={onPluginClick} />
          </span>
        </WhiteTooltip>
      ) : (
        <StyledTab icon={<ExcelIcon />} label={pluginText} onClick={onPluginClick} />
      )}
    </Tabs>
  );
};
export default Menu;
