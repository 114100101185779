import { gql } from '@apollo/client';

export const RESET_PASSWORD_SERVICE = gql`
  mutation sendPasswordResetLinkMutation($email: String!) {
    resetUserPassword(email: $email) {
      success
    }
  }
`;

export namespace ResetPasswordService {
  export type Params = {
    email: string;
  };
  export type Response = {
    resetUserPassword: {
      success: boolean;
    };
  };
}
