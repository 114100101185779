import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { setFeatureFlags } from 'store/slices';

type Props = {
  children: React.ReactNode;
};

export const MarketplaceVersionProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const isMarketplaceV2Enabled = useMemo(() => {
    const params = new URLSearchParams(search);
    return !params.get('v1');
  }, [search]);

  useEffect(() => {
    dispatch(setFeatureFlags({ isMarketplaceV2Enabled }));
  }, [dispatch, isMarketplaceV2Enabled]);

  return <>{children}</>;
};
