import { GENERAL_REDUCER_ACTIONS, GeneralActionCreator, GeneralReducerFunction } from '../types';

export const setFeatureFlags: GeneralActionCreator<GENERAL_REDUCER_ACTIONS.SET_FEATURE_FLAG> = (
  featureFlags
) => ({
  type: GENERAL_REDUCER_ACTIONS.SET_FEATURE_FLAG,
  value: featureFlags
});

export const setFeatureFlagsReducer: GeneralReducerFunction<GENERAL_REDUCER_ACTIONS.SET_FEATURE_FLAG> = (
  state,
  payload
) => ({
  ...state,
  featureFlags: {
    ...state.featureFlags,
    ...payload
  }
});
