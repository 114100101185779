import {
  StatsigProvider as SProvider,
  StatsigOptions,
  StatsigUser,
  useStatsigUser
} from '@statsig/react-bindings';
import { useSelectors } from 'presentation/hooks';
import React, { useEffect, useMemo } from 'react';

const STATSIG_CLIENT_SDK_KEY = 'client-6AfgU0iJZZ53gLe7FEshXvAX1pPqXDwLqJSa8uQNwT3';

const options: StatsigOptions = {
  environment: { tier: process.env.REACT_APP_ENV ?? 'development' }
};

const StatsigInner = ({ children }: { children: React.ReactNode }) => {
  const { updateUserAsync } = useStatsigUser();

  const email = useSelectors.User().email;

  const user: StatsigUser = useMemo(() => {
    if (!email) {
      return {
        userID: 'anonymous',
        email: ''
      };
    }

    return {
      userID: email,
      email
    };
  }, [email]);

  useEffect(() => {
    void updateUserAsync(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Can't add this loader because it breaks the theme provider by overwritting the theme styles
  // To fix it the login form needs to be updated so the dependency with mui core on V2 is removed

  // if (client.loadingStatus === 'Loading') {
  //   return <CircularProgress style={{ position: 'absolute', left: '50%' }} />;
  // }
  return <>{children}</>;
};

type Props = {
  children: React.ReactNode;
};

export const StatsigProvider = ({ children }: Props) => {
  const user: StatsigUser = useMemo(
    () => ({
      userID: 'anonymous',
      email: ''
    }),
    []
  );

  return (
    <SProvider sdkKey={STATSIG_CLIENT_SDK_KEY} options={options} user={user}>
      <StatsigInner>{children}</StatsigInner>
    </SProvider>
  );
};
