import React from 'react';

import useLocal from 'localization';

import Props from './types';
import { Link } from 'react-router-dom';
import { routes } from 'main/router';

const ModelFreeTrierTooltip: React.FC<Props> = ({ showNotAllowed }: Props) => {
  const accessNotAllowed = useLocal('access_not_allowed_trial_user');
  const inquireHere = useLocal('trial_here');
  const reachedMaxSubscription = useLocal('reached_max_subscription_trial_user');

  return (
    <>
      {showNotAllowed ? (
        <>
          {accessNotAllowed}{' '}
          <Link to={routes.INQUIRE_HERE_POPUP.concat('?v1=true')}>{inquireHere}</Link>
        </>
      ) : (
        <>
          {reachedMaxSubscription}{' '}
          <Link to={routes.INQUIRE_HERE_POPUP.concat('?v1=true')}>{inquireHere}</Link>
        </>
      )}
    </>
  );
};

export default ModelFreeTrierTooltip;
