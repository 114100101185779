import React, { useEffect, useMemo } from 'react';
import { DIALOG_IDS } from 'domain/models';
import { routes } from 'main/router';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toggleDialog } from 'store';

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const { token, uid } = useMemo(
    () => ({
      uid: params.get('uid'),
      token: params.get('token')
    }),
    [params]
  );

  useEffect(() => {
    if (!uid || !token) {
      navigate(routes.HOME);
      dispatch(toggleDialog({ action: 'close', id: DIALOG_IDS.UPDATE_PASSWORD }));
    } else {
      dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.UPDATE_PASSWORD }));
    }
  }, [dispatch, navigate, token, uid]);
  return <></>;
};
