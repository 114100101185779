import { Button, styled } from '@mui/material';

export const Container = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open: boolean;
}>(({ open, theme }) => ({
  height: '100vh',
  width: '80px',
  minWidth: '80px',
  transition: 'min-width 0.15s ease, width 0.15s ease',

  '& a': {
    textDecoration: 'none'
  },

  [theme.breakpoints.up('sm')]: {
    ...(open && {
      width: '290px',
      minWidth: '290px'
    }),
    ...(!open && {
      '& > div': {
        '& a': {
          border: 'none'
        },

        '& .daloopa-icon': {
          display: 'none'
        },

        '& .notifications-badge': {
          position: 'absolute',
          top: '8px',
          left: '40px',
          padding: 0,
          width: '10px',
          height: '10px',
          borderRadius: '100%',
          fontSize: 0
        }
      }
    })
  }
}));

export const ContentWrapper = styled('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1200,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: 'inherit',
  minWidth: 'inherit',
  padding: '16px',
  background: '#0D213B',
  overflowX: 'hidden'
}));

export const MenuHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '40px',

  '& > button': {
    width: '48px',
    height: '48px',
    padding: '8px'
  },

  '& .daloopa-icon': {
    display: 'none'
  },

  [theme.breakpoints.up('sm')]: {
    '& .daloopa-icon': {
      display: 'block'
    }
  }
}));

export const MenuMainNavigation = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

export const MenuSubNavigation = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 'auto',
  gap: '8px'
}));

export const MenuButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  width: '100%',
  minWidth: 'unset',
  height: '52px',
  alignItems: 'center',
  justifyContent: 'left',
  color: '#F2F2F2',
  padding: '12px',
  textTransform: 'none',
  fontSize: '16px',
  lineHeight: '28px',

  '&.menu-toggle': {
    pointerEvents: 'none'
  },

  '& svg': {
    fontSize: '24px',
    minWidth: '24px'
  },

  '&:hover': {
    backgroundColor: '#081221'
  },
  '&:active': {
    backgroundColor: '#263B54'
  },

  '& > div': {
    display: 'none'
  },

  [theme.breakpoints.up('sm')]: {
    '&.menu-toggle': {
      pointerEvents: 'auto'
    },
    '& > div': {
      display: 'block'
    }
  }
}));

export const OldMarketPlaceButton = styled('a')(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '8px',
  color: 'white',
  padding: '4px 10px',
  fontSize: '12px',
  textDecoration: 'none',
  textTransform: 'uppercase',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',

  '& svg': {
    fontSize: '18px',
    minWidth: '18px',
    minHeight: '18px'
  },
  '& span': {
    display: 'none',
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },

  '&:hover': {
    backgroundColor: '#263B54'
  },

  [theme.breakpoints.up('sm')]: {
    border: '1px solid white',

    '& span': {
      display: 'block'
    }
  }
}));

export const NotificationBadge = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '8px',
  left: '40px',
  padding: 0,
  width: '10px',
  height: '10px',
  borderRadius: '100%',
  fontSize: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  marginLeft: 'auto',
  background: '#EF5350',
  lineHeight: '20px',
  overflow: 'hidden',

  [theme.breakpoints.up('sm')]: {
    position: 'unset',
    width: 'fit-content',
    height: '20px',
    padding: '0 10px',
    borderRadius: '21px',
    fontSize: '12px'
  }
}));
