import { createSelector } from 'reselect';

import { ExcelPluginState } from './types';
import { RootState } from 'store/types';

const getRequestNewModelState = (state: RootState): ExcelPluginState => state.ExcelPluginReducer;

const hasError = createSelector(
  [getRequestNewModelState],
  (RequestNewModelState: ExcelPluginState): boolean => RequestNewModelState.isError
);
const getError = createSelector(
  [getRequestNewModelState],
  (RequestNewModelState: ExcelPluginState): string | null => RequestNewModelState.error
);
const isSuccessMsi = createSelector(
  [getRequestNewModelState],
  (RequestNewModelState: ExcelPluginState): boolean => RequestNewModelState.isSuccessMsi
);
const isSuccessExe = createSelector(
  [getRequestNewModelState],
  (RequestNewModelState: ExcelPluginState): boolean => RequestNewModelState.isSuccessExe
);

const isLoadingMsi = createSelector(
  [getRequestNewModelState],
  (RequestNewModelState: ExcelPluginState): boolean => RequestNewModelState.isLoadingMsi
);

const isLoadingExe = createSelector(
  [getRequestNewModelState],
  (RequestNewModelState: ExcelPluginState): boolean => RequestNewModelState.isLoadingExe
);

const excelVersion = createSelector(
  [getRequestNewModelState],
  (RequestNewModelState: ExcelPluginState): string | null => RequestNewModelState.excelVersion
);

export default {
  hasError,
  getError,
  isLoadingMsi,
  isLoadingExe,
  excelVersion,
  isSuccessMsi,
  isSuccessExe
};
