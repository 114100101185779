import React, { useMemo } from 'react';
import { Alert, Container } from './UpdatePasswordDialog.styles';
import { Button, Dialog, ErrorOutlinedIcon, TextInput } from 'presentation/components';
import { DIALOG_IDS } from 'domain/models';
import { useFormik } from 'formik';
import { object, ref, string } from 'yup';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { toggleDialog } from 'store';
import { useToastContext } from 'presentation/contexts';
import { validateComplexity, validateIdenticalCharacters } from 'validation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'main/router';
import { useAuthServices } from 'presentation/hooks';

const updatePasswordObjectShape = object({
  password: string()
    .required('Please enter a new password.')
    .min(8, 'Password needs to have at least 8 characters.')
    .max(128, 'Password must contain at most 128 characters.')
    .test(
      'consecutiveIdentical',
      'Password cannot contain more than 2 identical characters in a row',
      (password) => password === undefined || validateIdenticalCharacters(password)
    )
    .test({
      name: 'complexity',
      test: (value, context) => {
        if (value === undefined) {
          return true;
        }

        const errorMessage = validateComplexity(
          value,
          'Password must have %1 of the following complexity rules: %2'
        );
        if (errorMessage) {
          return context.createError({ message: errorMessage });
        }

        return true;
      }
    }),

  passwordConfirmation: string()
    .required('Please repeat your password.')
    .oneOf([ref('password'), null], 'Passwords must match')
});

export const UpdatePasswordDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToastContext();

  const [params] = useSearchParams();

  const [updatePassword, { loading, error }] = useAuthServices().updatePassword();

  const { token, uid } = useMemo(
    () => ({
      uid: params.get('uid') ?? '',
      token: params.get('token') ?? ''
    }),
    [params]
  );

  const formikObj = useFormik<{
    password: string;
    passwordConfirmation: string;
  }>({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: updatePasswordObjectShape,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ password }) => {
      void updatePassword({ variables: { password, token, uid } }).then(({ data }) => {
        if (data?.resetUserPasswordConfirm.success) {
          toast('Password updated successfully!');
          dispatch(toggleDialog({ action: 'close', id: DIALOG_IDS.UPDATE_PASSWORD }));
          formikObj.resetForm();
          navigate(routes.HOME);
        }
        if (data?.resetUserPasswordConfirm.errors?.length) {
          toast.error('Something went wrong. Please try again later.');
        }
      });
    }
  });

  const hasErrors = !formikObj.isValid || error;
  const submitDisabled = loading || !formikObj.isValid;
  const errorMessage = Object.values(formikObj.errors).slice(-1)[0];

  const handleClose = () => {
    formikObj.resetForm();
    navigate(routes.HOME);
  };

  return (
    <Dialog id={DIALOG_IDS.UPDATE_PASSWORD} onClose={handleClose} header="Reset Password">
      <Container>
        {hasErrors && (
          <Alert>
            <ErrorOutlinedIcon /> {errorMessage}
          </Alert>
        )}
        <form onSubmit={formikObj.handleSubmit}>
          <TextInput
            name="password"
            placeholder="Enter password"
            label="Update Password"
            autoComplete="off"
            type="password"
            onChange={formikObj.handleChange}
            value={formikObj.values.password}
            onBlur={formikObj.handleBlur}
            error={!!formikObj.errors.password}
            disabled={loading}
          />
          <TextInput
            name="passwordConfirmation"
            placeholder="Repeat password"
            label="Confirm Password"
            autoComplete="off"
            type="password"
            onChange={formikObj.handleChange}
            value={formikObj.values.passwordConfirmation}
            onBlur={formikObj.handleBlur}
            error={!!formikObj.errors.passwordConfirmation}
            disabled={loading}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            className="reset-button"
            disabled={submitDisabled}
          >
            {!loading && 'Reset password'}
            {loading && <CircularProgress size={24} />}
          </Button>
        </form>
      </Container>
    </Dialog>
  );
};
