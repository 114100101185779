import React, { useMemo } from 'react';
import { Alert, Container, Details } from './SignupDialog.styles';
import { Button, Dialog, ErrorOutlinedIcon, TextInput } from 'presentation/components';
import { DIALOG_IDS } from 'domain/models';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { toggleDialog } from 'store';
import { useToastContext } from 'presentation/contexts';
import { validateComplexity, validateIdenticalCharacters } from 'validation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'main/router';
import { useAuthServices } from 'presentation/hooks';

const signupObjectShape = object({
  password: string()
    .required('Please enter a new password.')
    .min(8, 'Password needs to have at least 8 characters.')
    .max(128, 'Password must contain at most 128 characters.')
    .test(
      'consecutiveIdentical',
      'Password cannot contain more than 2 identical characters in a row',
      (password) => password === undefined || validateIdenticalCharacters(password)
    )
    .test({
      name: 'complexity',
      test: (value, context) => {
        if (value === undefined) {
          return true;
        }

        const errorMessage = validateComplexity(
          value,
          'Password must have %1 of the following complexity rules: %2'
        );
        if (errorMessage) {
          return context.createError({ message: errorMessage });
        }

        return true;
      }
    })
});

export const SignupDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToastContext();

  const [params] = useSearchParams();

  const [signUp, { loading, error }] = useAuthServices().signup();

  const { email, hashcode } = useMemo(
    () => ({
      email: params.get('email') ?? '',
      hashcode: params.get('hashcode') ?? ''
    }),
    [params]
  );

  const formikObj = useFormik<{
    email: string;
    password: string;
  }>({
    initialValues: {
      email,
      password: ''
    },
    validationSchema: signupObjectShape,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ email, password }) => {
      void signUp({ variables: { email, password, hashcode } }).then(({ data }) => {
        const success = data?.createNewUser?.success;
        const errors = data?.createNewUser?.errors;
        if (success) {
          toast('Account created successfully!');
          dispatch(toggleDialog({ action: 'close', id: DIALOG_IDS.SIGNUP }));
          formikObj.resetForm();
          navigate(routes.HOME);
        }
        if (errors?.length) {
          if (errors.includes('WRONG_LINK')) {
            toast.error('Invalid link. Please try again.');
          } else {
            toast.error('Something went wrong. Please try again later.');
          }
        }
      });
    }
  });

  const hasErrors = !formikObj.isValid || error;
  const submitDisabled = loading || !formikObj.isValid;
  const errorMessage = Object.values(formikObj.errors).slice(-1)[0];

  const handleClose = () => {
    formikObj.resetForm();
    navigate(routes.HOME);
  };

  return (
    <Dialog id={DIALOG_IDS.SIGNUP} onClose={handleClose} header="Set Password">
      <Details>Let&apos;s create your first password!</Details>
      <Container>
        {hasErrors && (
          <Alert>
            <ErrorOutlinedIcon /> {errorMessage}
          </Alert>
        )}
        <form onSubmit={formikObj.handleSubmit}>
          <TextInput
            name="email"
            placeholder="Enter your email"
            label="Email Address"
            autoComplete="off"
            onChange={formikObj.handleChange}
            value={formikObj.values.email}
            onBlur={formikObj.handleBlur}
            error={!!formikObj.errors.email}
            disabled={loading}
          />
          <TextInput
            name="password"
            placeholder="Enter your password"
            label="Password"
            autoComplete="off"
            type="password"
            onChange={formikObj.handleChange}
            value={formikObj.values.password}
            onBlur={formikObj.handleBlur}
            error={!!formikObj.errors.password}
            disabled={loading}
          />
          <Button type="submit" variant="contained" size="large" disabled={submitDisabled}>
            {!loading && 'Set Password'}
            {loading && <CircularProgress size={24} />}
          </Button>
        </form>
      </Container>
    </Dialog>
  );
};
