import {
  AUTH_REDUCER_ACTIONS,
  AUTH_REDUCER_INITIAL_STATE,
  AuthActionCreator,
  AuthReducerFunction
} from '../types';

export const authStateCleared: AuthActionCreator<AUTH_REDUCER_ACTIONS.AUTH_STATE_CLEARED> = () => ({
  type: AUTH_REDUCER_ACTIONS.AUTH_STATE_CLEARED
});

export const authStateClearedReducer: AuthReducerFunction<AUTH_REDUCER_ACTIONS.AUTH_STATE_CLEARED> = () =>
  AUTH_REDUCER_INITIAL_STATE;
