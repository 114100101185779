import { setMenuStateReducer, toggleDialogsReducer } from './actions';
import { generalStateClearedReducer } from './actions/generalStateCleared';
import { setFeatureFlagsReducer } from './actions/setFeatureFlags';
import { setV2BannerClosedReducer } from './actions/setV2BannerClosed';
import {
  GENERAL_REDUCER_ACTIONS,
  GENERAL_REDUCER_INITIAL_STATE,
  GeneralCaseReducer,
  GeneralState
} from './types';

export const generalReducer: GeneralCaseReducer = (
  state: GeneralState = GENERAL_REDUCER_INITIAL_STATE,
  { type, value }
) => {
  switch (type) {
    case GENERAL_REDUCER_ACTIONS.GENERAL_STATE_CLEARED:
      return generalStateClearedReducer(state);
    case GENERAL_REDUCER_ACTIONS.SET_FEATURE_FLAG:
      return setFeatureFlagsReducer(state, value);
    case GENERAL_REDUCER_ACTIONS.SET_MENU_STATE:
      return setMenuStateReducer(state, value);
    case GENERAL_REDUCER_ACTIONS.SET_V2_BANNER_CLOSED:
      return setV2BannerClosedReducer(state, value);
    case GENERAL_REDUCER_ACTIONS.TOGGLE_DIALOGS:
      return toggleDialogsReducer(state, value);
    default:
      return state;
  }
};
