import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LeftArrowIcon, SearchIcon, Tooltip } from 'presentation/components';
import { routes } from 'main/router';
import { Container, Input, InputWrapper } from './SearchInput.styles';
import { Mixpanel } from 'mixPanel';
import { useDebounce } from 'presentation/hooks';

export const SearchInput = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const isSearchPageRef = useRef(location.pathname.includes(routes.SEARCH));
  const lastQuery = useRef(params.get('q'));

  const [query, setQuery] = useState(decodeURIComponent(params.get('q') ?? ''));
  const debouncedQuery = useDebounce(query);

  const routesWithoutSearch = [routes.SETTINGS];
  const isSearchPage = location.pathname.includes(routes.SEARCH);

  const handleNavigate = useCallback(
    (query: string) => {
      if (!query.trim()) return;

      const from = !isSearchPage ? location.pathname : undefined;

      Mixpanel.track('marketplaceV2:search', { query });

      navigate(
        { pathname: routes.SEARCH, search: `?q=${encodeURIComponent(query.trim())}` },
        { state: { from } }
      );
    },
    [isSearchPage, location.pathname, navigate]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.trimStart());
  };

  const handleSearch = () => {
    handleNavigate(query);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleBack = () => {
    setQuery('');
  };

  useEffect(() => {
    if (isSearchPageRef.current && !isSearchPage) {
      setQuery('');
    }

    isSearchPageRef.current = isSearchPage;
  }, [isSearchPage]);

  useEffect(() => {
    if (!query || debouncedQuery === lastQuery.current) return;

    lastQuery.current = debouncedQuery;

    handleNavigate(debouncedQuery);
  }, [debouncedQuery, handleNavigate, query]);

  if (routesWithoutSearch.includes(location.pathname)) return <></>;

  return (
    <Container>
      {isSearchPage && (
        <Link to={location.state?.from ?? routes.HOME} onClick={handleBack}>
          <LeftArrowIcon />
        </Link>
      )}
      <InputWrapper onKeyUp={handleKeyUp}>
        <Input
          type="text"
          placeholder="Search data sheets by ticker or company name"
          value={query}
          onChange={handleChange}
          maxLength={100}
        />
        <Tooltip title="Search">
          <SearchIcon onClick={handleSearch} />
        </Tooltip>
      </InputWrapper>
    </Container>
  );
};
