import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { authState, generalState, userState } from 'store/slices';

export const Auth = () => {
  const reduxState = useAppSelector(authState);

  return useMemo(() => reduxState, [reduxState]);
};
export const General = () => {
  const reduxState = useAppSelector(generalState);

  return useMemo(() => reduxState, [reduxState]);
};
export const User = () => {
  const reduxState = useAppSelector(userState);

  return useMemo(() => reduxState, [reduxState]);
};
