import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',

  '& a button': {
    width: '100%'
  }
});

export const Details = styled('span')({
  fontSize: '14px',
  color: '#60646C'
});

export const Alert = styled('div')({
  display: 'flex',
  gap: '0.75rem',
  alignItems: 'center',
  padding: '0.875rem 1rem',
  background: '#FDEDED',
  borderRadius: '4px',
  color: '#5F2120',
  fontWeight: 500,

  '& svg': {
    fontSize: '22px'
  }
});
