import React from 'react';

import { ENUMS } from './constants';

import DemoPopup from 'components/DemoPopup';
import UnSubscribeModelPopup from 'components/UnSubscribeModelPopup';
import { routes } from 'main/router';
import ApiSettings from 'pages/ApiSettings';
import DownloadModel from 'pages/DownloadModel';
import ExcelPlugin from 'pages/ExcelPlugin';
import RequestModel from 'pages/RequestModel';
import RequestNewModel from 'pages/RequestNewModel';
import { useSelectors } from 'presentation/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import usePopupState from './hooks/usePopupState';
import { useDispatch } from 'react-redux';
import { toggleDialog } from 'store';
import { DIALOG_IDS } from 'domain/models';

const popups = {
  [ENUMS.popup.demoPopup]: { component: DemoPopup, loginRequired: true },
  [ENUMS.popup.requestModel]: { component: RequestModel, loginRequired: true },
  [ENUMS.popup.addNewModel]: { component: RequestNewModel, loginRequired: true },
  [ENUMS.popup.apiSettings]: { component: ApiSettings, loginRequired: true },
  [ENUMS.popup.excelPlugin]: { component: ExcelPlugin, loginRequired: true },
  [ENUMS.popup.downloadModel]: { component: DownloadModel, loginRequired: true },
  [ENUMS.popup.unsubscribeModel]: { component: UnSubscribeModelPopup, loginRequired: true }
};

const Popup: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { mountedPopup, isOpened } = usePopupState();

  const { isAuthenticated } = useSelectors.Auth();

  const onClose = () => {
    navigate(location.pathname.concat('?v1=true'));
  };
  const loginRequired = mountedPopup != null ? popups[mountedPopup]?.loginRequired : false;
  if (loginRequired && !isAuthenticated) {
    dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
    return <></>;
  }
  const Component = mountedPopup != null ? popups[mountedPopup]?.component : null;

  if (!Component) {
    return null;
  }

  return <Component isOpened={isOpened} onClose={onClose} />;
};

export default React.memo(Popup);
