import queryString from 'query-string';

export const setQueryParams = (params: Record<string, any>) => {
  if (window) {
    window.history.pushState({}, '', `?${queryString.stringify(params)}`);
  }
};

export const getQueryParams = () => {
  if (window) {
    return queryString.parse(window.location.search);
  }
  return {};
};

export const updateQueryParams = (obj: Record<string, unknown>) => {
  const params = getQueryParams();
  const updatedParams = { ...params, ...obj };
  for (const key in updatedParams) {
    if (updatedParams[key] === undefined || obj[key] === null) {
      delete updatedParams[key];
    }
  }
  setQueryParams(updatedParams);
};

export const getTimeFormatted = (seconds: number | undefined) => {
  let unit = 'min';
  let value = (seconds ?? 0) / 60; // min
  if (value >= 60) {
    value /= 60; //hours
    unit = 'hrs';
  }
  if (unit === 'hrs' && value >= 24) {
    value /= 24; //days
    unit = 'days';
  }
  return { unit, value: value.toFixed(1) };
};
