import { createSelector } from 'reselect';

import { RequestModelState, companyObj } from './types';
import { RootState } from 'store/types';

const getRequestModelState = (state: RootState): RequestModelState => state.RequestModelReducer;

const hasError = createSelector(
  [getRequestModelState],
  (RequestModelState: RequestModelState): boolean => RequestModelState.isError
);
const getError = createSelector(
  [getRequestModelState],
  (RequestModelState: RequestModelState): string | null => RequestModelState.error
);

const isLoading = createSelector(
  [getRequestModelState],
  (RequestModelState: RequestModelState): boolean => RequestModelState.isLoading
);

const companyList = createSelector(
  [getRequestModelState],
  (RequestModelState: RequestModelState): companyObj[] | [] => RequestModelState.companyList
);

const selectedCompanyId = createSelector(
  [getRequestModelState],
  (RequestModelState: RequestModelState): string | null => RequestModelState.selectedCompanyId
);

export default {
  hasError,
  getError,
  isLoading,
  companyList,
  selectedCompanyId
};
