import { useStatsigClient } from '@statsig/react-bindings';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFeatureFlags } from 'store';

type Props = {
  children: React.ReactNode;
};

export const FeatureFlagValidationProvider = ({ children }: Props) => {
  const dispatch = useDispatch();

  const { client } = useStatsigClient();

  const industryModelsEnabled = client.checkGate('enable-industry-models');
  useEffect(() => {
    dispatch(setFeatureFlags({ industryModelsEnabled }));
  }, [dispatch, industryModelsEnabled]);

  return <>{children}</>;
};
