import { createSelector } from 'reselect';

import { IndustryModelsState, IndustryModal } from './types';
import { RootState } from 'store/types';
const getIndustryModelState = (state: RootState): IndustryModelsState => state.IndustryModelReducer;

/**
 * Sectors
 */
const industryModelsError = createSelector(
  [getIndustryModelState],
  (industryModelsState: IndustryModelsState): string | undefined =>
    industryModelsState.industryModelsError
);

const isIndustryModelsLoading = createSelector(
  [getIndustryModelState],
  (industryModelsState: IndustryModelsState): boolean => industryModelsState.isIndustryModelsLoading
);

const getIndustryModels = createSelector(
  [getIndustryModelState],
  (industryModelsState: IndustryModelsState): IndustryModal[] => industryModelsState.industryModels
);

export default {
  industryModelsError,
  isIndustryModelsLoading,
  getIndustryModels
};
