import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ModelsTable } from 'presentation/components';
import { routes } from 'main/router';

export const SearchPage = () => {
  const [params] = useSearchParams();

  const query = decodeURIComponent(params.get('q') ?? '').trim();

  if (!query) return <Navigate to={routes.HOME} />;

  return <ModelsTable isSearchPage keyword={query} />;
};

export default SearchPage;
