import React from 'react';

export const ErrorOutlinedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0833 13.75H11.9166V15.5833H10.0833V13.75ZM10.0833 6.41665H11.9166V11.9166H10.0833V6.41665ZM10.9908 1.83331C5.93075 1.83331 1.83325 5.93998 1.83325 11C1.83325 16.06 5.93075 20.1666 10.9908 20.1666C16.0599 20.1666 20.1666 16.06 20.1666 11C20.1666 5.93998 16.0599 1.83331 10.9908 1.83331ZM10.9999 18.3333C6.94825 18.3333 3.66659 15.0516 3.66659 11C3.66659 6.94831 6.94825 3.66665 10.9999 3.66665C15.0516 3.66665 18.3333 6.94831 18.3333 11C18.3333 15.0516 15.0516 18.3333 10.9999 18.3333Z"
      fill="#D32F2F"
    />
  </svg>
);
