import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%'
});

export const AuthorizedContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxHeight: 'calc(100vh - 115px)',
  width: '100%',
  overflow: 'hidden',

  [theme.breakpoints.up('md')]: {
    maxHeight: 'calc(100vh - 145px)'
  }
}));

export const SplashText = styled('span')({
  fontSize: '16px',
  textAlign: 'center',
  maxWidth: '435px',
  margin: '10px auto',
  color: '#59595C'
});
