import React, { useCallback, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useSelectors } from 'presentation/hooks';

type Props = {
  children: React.ReactNode;
};

export const DatadogProvider = ({ children }: Props) => {
  const { isAuthenticated } = useSelectors.Auth();
  const { email } = useSelectors.User();

  const clearSession = useCallback(() => {
    datadogRum.stopSession();
    datadogRum.clearUser();
    datadogRum.stopSessionReplayRecording();
  }, []);

  useEffect(() => {
    if (
      isAuthenticated &&
      process.env.REACT_APP_DATADOG_APPLICATION_ID &&
      process.env.REACT_APP_DATADOG_CLIENT_TOKEN &&
      process.env.REACT_APP_ENV === 'production'
    ) {
      datadogRum.init({
        allowFallbackToLocalStorage: true,
        allowedTracingUrls: [/https:\/\/.*\.daloopa\.com\/gql/],
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        defaultPrivacyLevel: 'allow',
        enableExperimentalFeatures: ['feature_flags'],
        env: 'prod',
        service: 'marketplace',
        sessionReplaySampleRate: 50,
        sessionSampleRate: 100,
        site: 'datadoghq.com',
        startSessionReplayRecordingManually: true,
        trackLongTasks: true,
        trackResources: true,
        trackSessionAcrossSubdomains: true,
        trackUserInteractions: true
      });

      datadogRum.onReady(() => {
        datadogRum.setUser({
          id: email,
          email
        });
        datadogRum.startSessionReplayRecording();
      });
    } else {
      clearSession();
    }

    return () => {
      clearSession();
    };
  }, [clearSession, isAuthenticated, email]);

  return <>{children}</>;
};
