import React, { Component, useEffect } from 'react';
import { DefaultLayout } from 'main/layout';
import { useToastContext } from 'presentation/contexts';

type Props = {
  children?: React.ReactNode;
};

const RouterErrorBoundaryInner = ({ children }: Props) => {
  const toast = useToastContext();

  useEffect(() => {
    toast.error('An error occurred. Please try again later.');
  }, [toast]);
  return (
    <>
      <h2 style={{ margin: '100px auto' }}>Something went wrong. Please try again later.</h2>
      {children}
    </>
  );
};

export const RouterErrorBoundary = ({ children }: Props) => {
  return (
    <DefaultLayout>
      <RouterErrorBoundaryInner>{children}</RouterErrorBoundaryInner>
    </DefaultLayout>
  );
};

export class ErrorBoundary extends Component {
  state: { hasError: boolean } = { hasError: false };
  constructor(props: Props) {
    super(props);
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <RouterErrorBoundary />;
    }

    return this.props.children;
  }
}
