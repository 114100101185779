import React from 'react';

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="1em" height="1em" fill="currentColor" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5671 4.30044C12.8066 4.06092 12.8066 3.67258 12.5671 3.43306C12.3276 3.19354 11.9392 3.19354 11.6998 3.43306L8.00009 7.13271L4.30045 3.43306C4.06092 3.19354 3.67258 3.19354 3.43306 3.43306C3.19354 3.67258 3.19354 4.06092 3.43306 4.30044L7.13271 8.00009L3.43306 11.6997C3.19354 11.9393 3.19354 12.3276 3.43306 12.5671C3.67258 12.8066 4.06092 12.8066 4.30045 12.5671L8.00009 8.86747L11.6998 12.5671C11.9392 12.8066 12.3276 12.8066 12.5671 12.5671C12.8066 12.3276 12.8066 11.9393 12.5671 11.6997L8.86747 8.00009L12.5671 4.30044Z"
      fill="currentColor"
    />
  </svg>
);
