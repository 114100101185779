import { styled, Button } from '@mui/material';

export const StyledButton = styled(Button)(() => ({
  '&.MuiButton-containedPrimary': {
    background: '#263B54',

    '&.Mui-disabled': {
      background: '#0000001F'
    }
  },

  '&.MuiButton-outlinedPrimary': {
    borderColor: '#263B54',
    color: '#263B54'
  },

  '&.MuiButton-textPrimary': {
    color: '#263B54'
  },
  '&.MuiButton-textSecondary': {
    color: '#00000099',

    '&:hover': {
      background: '#0000000a'
    }
  }
}));
