import React, { memo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Models from 'pages/Models';

import TagViewer from 'pages/TagViewer';
import InquireHerePopup from 'pages/InquireHerePopup';
import TextFundamental from 'pages/TextFundamental';
import LiveModelPreview from 'pages/LiveModelPreview';
import RolledUpNumbers from '../../pages/RolledUpNumbers';
import { getProtectedRoute } from 'main/router/ProtectedRoute';
import { routes } from './routes';
import { useSelectors } from 'presentation/hooks';

export const RouterV1 = memo(() => {
  const { isAuthenticated } = useSelectors.Auth();

  return (
    <Routes>
      <Route
        path={routes.LIVE_PREVIEW}
        element={getProtectedRoute({
          element: <LiveModelPreview />,
          isAuthenticated
        })}
      />
      <Route
        path={routes.TAG_VIEWER}
        element={getProtectedRoute({
          ipWhitelisted: true,
          element: <TagViewer />,
          isAuthenticated
        })}
      />
      <Route
        path={routes.DOC_VIEWER}
        element={getProtectedRoute({
          ipWhitelisted: true,
          element: <TagViewer isDocument />,
          isAuthenticated
        })}
      />
      <Route
        path={routes.TEXT_FUNDAMENTAL}
        element={getProtectedRoute({
          ipWhitelisted: true,
          element: <TextFundamental />,
          isAuthenticated
        })}
      />
      <Route
        path={routes.ROLLUP_NUMBERS}
        element={getProtectedRoute({
          ipWhitelisted: true,
          element: <RolledUpNumbers />,
          isAuthenticated
        })}
      />
      <Route path={routes.INQUIRE_HERE_POPUP} element={<InquireHerePopup />} />
      <Route path={routes.MODELS} element={<Models />} />
      <Route path={routes.HOME} element={<Models />} />
      <Route path="*" element={<Navigate to={`${routes.HOME}?v1=true`} replace />} />
    </Routes>
  );
});
