import { ProductType, UserTier } from 'core/domain';

export enum UserType {
  INTERNAL = 'internal',
  EXTERNAL = 'external'
}

export type UserData = {
  id: string;
  allowedSectors: [string] | null;
  email: string;
  firstName?: string | null;
  isAdmin?: boolean;
  lastName?: string | null;
  maxSubscriptions?: number;
  phoneNumber?: number | null;
  productType: ProductType;
  receiveModelUpdateNotification: boolean;
  subscribedCount: number;
  tier: UserTier;
  token: UserType;
  userType: UserType;
};

export type UserSettings = {
  notifications: {
    receiveAutotaggingCompletedUpdates: boolean;
    receiveDocumentIngestedUpdates: boolean;
    receiveIntraQuarterModificationUpdates: boolean;
    receiveIntraQuarterNewRowsUpdates: boolean;
    receiveNewClientViewUpdates: boolean;
  };
};
