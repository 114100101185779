import React, { useEffect, useMemo } from 'react';
import { DIALOG_IDS } from 'domain/models';
import { routes } from 'main/router';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toggleDialog } from 'store';

export const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const { email, hashcode } = useMemo(
    () => ({
      email: params.get('email'),
      hashcode: params.get('hashcode')
    }),
    [params]
  );

  useEffect(() => {
    if (!email || !hashcode) {
      navigate(routes.HOME);
      dispatch(toggleDialog({ action: 'close', id: DIALOG_IDS.SIGNUP }));
    } else {
      dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.SIGNUP }));
    }
  }, [dispatch, email, hashcode, navigate]);
  return <></>;
};
