import React from 'react';

export const EyeOpenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3329 13.6301C13.4784 13.6301 14.4069 12.7015 14.4069 11.556C14.4069 10.4105 13.4784 9.48193 12.3329 9.48193C11.1874 9.48193 10.2588 10.4105 10.2588 11.556C10.2588 12.7015 11.1874 13.6301 12.3329 13.6301Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6667 11.5557C19.7081 14.6575 16.1893 17.7779 12.3333 17.7779C8.47732 17.7779 4.95851 14.6575 3 11.5557C5.38368 8.60872 8.17651 5.3335 12.3333 5.3335C16.4902 5.3335 19.283 8.60867 21.6667 11.5557Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
