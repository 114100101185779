export const routes = {
  /**
   * Updated Routes
   */
  HOME: '/',
  SEARCH: '/search',
  MODELS: '/models',
  SETTINGS: '/settings',
  INDUSTRY: '/industry-models',

  /**
   * Legacy Routes
   */
  TAG_VIEWER: '/src/:id',
  DOC_VIEWER: '/document/:id',
  TEXT_FUNDAMENTAL: '/text-fundamental',
  INQUIRE_HERE_POPUP: '/inquire-here',
  LIVE_PREVIEW: '/preview/:companyId',

  /**
   * Auth Routes
   */
  SIGN_UP: '/sign-up',
  VERIFY_EMAIL: '/verify-email',
  RESET_PASSWORD: '/reset-password',

  /**
   * Generic popups
   */
  REQUEST_A_MODEL: '?v1=true&popup=request-a-model',
  ADD_NEW_MODEL: '?v1=true&popup=add-new-model',
  API_SETTINGS: '?v1=true&popup=api-settings',
  EXCEL_PLUGIN: '?v1=true&popup=excel-plugin',
  DOWNLOAD_MODEL: '?popup=download-model',
  UNSUBSCRIBE_MODEL: '?v1=true&popup=unsubscribe-model',
  ROLLUP_NUMBERS: '/rollup',

  /**
   * Error Pages
   */
  ERROR_404: '/404',
  ERROR_403: '/403',
  ERROR_401: '/401',
  ERROR_500: '/500'
};

export const openRoutesList = [routes.SEARCH];
