import { Button, styled, TableBody, TableHead } from '@mui/material';

export const Container = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  paddingBottom: '24px'
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '16px',
  width: '100%',
  minHeight: 'fit-content',

  '& > div:last-of-type': {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center'
  }
}));

export const HeaderLabel = styled('div')(({ theme }) => ({
  minWidth: 'fit-content',
  fontSize: '16px',
  fontWeight: 'bold',

  [theme.breakpoints.up('md')]: {
    fontSize: '20px'
  }
}));
export const ItemCount = styled('div')({
  minWidth: 'fit-content',
  fontSize: '16px',
  color: 'rgba(0, 0, 0, 0.6)',
  marginRight: 'auto'
});

export const TableWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  overflow: 'auto',

  '& table': {
    borderCollapse: 'separate'
  },
  '& .MuiTablePagination-root': {
    display: 'none'
  }
}));

export const StyledTableHead = styled(TableHead)(() => ({
  background: '#FAFAFA',
  borderTop: 'solid 1px',
  borderColor: '#E8E8E8',

  '&, & th': {
    position: 'sticky',
    top: 0,
    borderTop: 'solid 1px',
    borderColor: '#E8E8E8',
    zIndex: 1000,
    background: '#FAFAFA',

    '&.model-name': {
      width: '100%'
    }
  },

  '& > tr': {
    height: '35px'
  },

  '& th': {
    minWidth: '147px',
    padding: '4px 16px 0',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}));

export const StyledTableBody = styled(TableBody)(() => ({
  '& > tr': {
    height: '54px',
    maxHeight: '54px',

    '&.industry': {
      height: '35px',
      maxHeight: '35px',

      '& > td': {
        color: '#00000099',
        fontSize: '12px',
        fontWeight: 500,
        background: '#E8E8E8'
      }
    }
  },

  '& td': {
    padding: '0 16px',
    maxWidth: '170px',
    background: 'white',
    whiteSpace: 'nowrap',

    '& > div': {
      color: '#000000DE',
      fontSize: '14px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  }
}));

export const ActionButton = styled(Button)(() => ({
  gap: '8px',
  minWidth: 'unset',
  width: '115px',
  height: '30px',
  border: '1px solid #E8E8E8',
  borderRadius: '4px',
  background: '#E8E8E8',
  color: '#000000DE',
  fontSize: '12px',
  fontWeight: 'bold',

  '&:hover': {
    background: '#F8F8F8'
  },

  '& svg': {
    minWidth: '18px',
    minHeight: '18px'
  }
}));
